import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';


type ContainerProps = {
  backgroundColor: string;
};
export const Container = styled(Box)<ContainerProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  position: 'relative',
  [theme.breakpoints.down('md')]: {},
}));


export const Rectangle = styled(Box)(({ theme }) => ({
  width: pxToRem(24),
  height: pxToRem(3),
  marginBottom: pxToRem(16),
  backgroundColor: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    marginBottom: pxToRem(24),
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${pxToRem(140)} ${pxToRem(globalSettings.Container.xl)}`,
  gap: pxToRem(46),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(24),
    padding: `${pxToRem(105)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));

export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    
  },
  [theme.breakpoints.between('md', 'lg')]: {
  
  },
}));

export const RightSideWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    
  },
  [theme.breakpoints.between('md', 'lg')]: {
  
  },
}));


export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(40),
  lineHeight: '120%',
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(36),
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  width: '100%',
  lineHeight: pxToRem(24),
  '& p': {
    opacity: 0.7,
  },
  '& li': {
    opacity: 0.7,
    fontFamily: 'Public Sans',
    fontSize: pxToRem(16),
  },
  '& h5': {
    fontSize: pxToRem(24),
    fontWeight: 400,
    lineHeight: '120%'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& h5': {
      fontSize: pxToRem(20),
      fontWeight: 500,
      lineHeight: pxToRem(24),
      marginTop: 0
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
