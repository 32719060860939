import { Box, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import pxToRem from 'src/utils/functions/pxToRem';
import getIcon from 'src/utils/getIcon';
import getIconAnimation from 'src/utils/getIconAnimation';
import {
    Heading,
    Description,
    Container,
    ContentWrapper,
    TopSectionWrapper,
    TopSectionRightWrapper,
    MaskWrapper
} from './styles';
import ImageGrid from './components/ImageGrid';
import DataGrid from './components/DataGrid';


const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

interface DBDataProps {
    DBDataNumber: number;
    DBDataItem: string
}
interface ImageProps {
    DBImage: string
}

interface CTABannerProps {
    DBTitle: string;
    DBTitleSize: string;
    DBContentColumnOne: string;
    DBContentColumnTwo: string;
    DBImages: ImageProps[];
    DBMask: string;
    DBData: DBDataProps[]
}
export default function DataBanner({
    DBTitle,
    DBTitleSize,
    DBContentColumnOne,
    DBContentColumnTwo,
    DBImages,
    DBMask,
    DBData
}: CTABannerProps) {
    const isMobile = useResponsive('down', 'md');

    
    return (
        <Container className='DataBanner'>

            <ContentWrapper>
                <MaskWrapper
                    initial={{ rotate: 0, x: 0 }}
                    animate={getIconAnimation(DBMask)}
                >
                    {getIcon(DBMask)}
                </MaskWrapper>
                <TopSectionWrapper>
                    <Heading sx={[globalSettings.FontProperty.TextBold]} size={DBTitleSize} dangerouslySetInnerHTML={{ __html: DBTitle }} />
                    <TopSectionRightWrapper>
                        <Description dangerouslySetInnerHTML={{ __html: DBContentColumnOne }} />
                        <Description dangerouslySetInnerHTML={{ __html: DBContentColumnTwo }} />
                    </TopSectionRightWrapper>
                </TopSectionWrapper>

                <ImageGrid images={DBImages} />
                <Box paddingTop={isMobile ? pxToRem(40) : pxToRem(80)} position="relative" zIndex={3}>
                    <DataGrid data={DBData} />
                </Box>
            </ContentWrapper>
        </Container>
    )
}
