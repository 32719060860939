import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import { fetchDataFromAPI, url_format } from 'src/atoms/apiConfig';
import MainButton from 'src/components/Button';
import Image from 'src/components/Image';
import { useState, useEffect } from 'react';


const SERVICE_ENDPOINT = "services/?id="

const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 99,
    // width: '240px',
    maxWidth: pxToRem(446),
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.up('xl')]: {
        minWidth: '100%'
    },

}));

const ContentWrapper = styled(Box)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
    },
}));


const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(28),
    padding: pxToRem(20),
    paddingBottom: pxToRem(8),
    color: globalSettings.Colors.primary,
    textAlign: 'center',
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(18),
        width: '100%',

    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',

    },
}));


const ImageBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: pxToRem(256),
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: pxToRem(201),
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',
        height: '10rem'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '100%',
        height: '10rem'
    },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    '& button': {
        paddingTop: 0,
        paddingBottom: '20px'
    },
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.between('md', 'lg')]: {

    },
    [theme.breakpoints.between('lg', 'xl')]: {

    },
}));

interface DataProps {
    link: string;
    title: {
        rendered: string
    };
    acf: {
        ServiceImage: string;
    }
    content: {
        rendered: string
    };
}
interface CardProps {
    id: number;
    width?: number;
}
export default function Card({ id, width }: CardProps) {
    const [data, setData] = useState<DataProps | null>(null);
    const ButtonGroup = {
        ButtonVariant: "primary2",
        Button: {
            title: "Learn more",
            url: data?.link || "",
            target: ""
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${url_format}${SERVICE_ENDPOINT}${id}`
            const { data: res } = await fetchDataFromAPI(endpoint);
            setData(res[0])
        }

        fetchData();
    }, [id])

    return (
        <Container sx={{ maxWidth: pxToRem(width || 446) || pxToRem(446) }}>
            <ContentWrapper>
                <ImageBox>
                    <Image
                        src={`https://${REACT_APP_CMS_HOST}${data?.acf.ServiceImage}`}
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </ImageBox>


                <Title sx={[globalSettings.FontProperty.TextSemiBold]}
                    dangerouslySetInnerHTML={{ __html: data?.title.rendered || "" }}
                />
                <ButtonContainer>
                    <MainButton ButtonGroup={ButtonGroup} />
                </ButtonContainer>
            </ContentWrapper>
        </Container>
    )
}
