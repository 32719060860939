import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useRecoilState } from 'recoil';
import { snackbarAtom } from 'src/atoms/snackbar';
import { atomMap } from 'src/atoms';
import useResponsive from 'src/hooks/useResponsive';
import Footer from 'src/components/Footer';
import Header from '../components/Header';
import { checkPageStatus, fetchDataFromAPI, url_format } from '../atoms/apiConfig';
import LoadingStatic from '../components/LoadingStatic';
import ErrorScreen from '../components/404';
import './styles.css'
import components from './componentList';


const Page: React.FC<any> = () =>  {
  const currentUrl = window.location.pathname.split('/').filter(item=>item!=='')
  const searchParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};
  searchParams.forEach((value: string, key: string) => {
  params[key] = value;
  });

  const base = currentUrl[0]
  const slug = currentUrl[currentUrl.length-1]

  const [pageComponents, setPageComponents] = useState<any[]>([])
  const [error, setError] = useState('')

  const [snackbar, setSnackbar] = useRecoilState(snackbarAtom)
  const [settings] = useRecoilState(atomMap.settings)
  const [redirectFinished, setRedirectFinished] = useState(false)
  const [pageCode, setPageCode] = useState()

  const isMobile = useResponsive('down', 768)

  const fetchEndPoint = async () => {
    setError('')
    let api_endpoint = '';
    if (slug === "preview") {
       api_endpoint = currentUrl.length > 1 ? `${url_format}${base}${slug}/?pid=${params.pid}&cpt=${params.cpt}` : `${url_format}${slug}/?pid=${params.pid}&cpt=${params.cpt}`
    }else{
       api_endpoint = currentUrl.length > 1 ? `${url_format}${base}?slug=${slug}` : `${url_format}pages/?slug=${slug}`
    }
    const url = window.location.pathname === '/' ? `${url_format}${base || ''}pages/?slug=home` : api_endpoint
    try {
      const responseData = await fetchDataFromAPI(url);
      if(responseData.data){
        const flexible_components = responseData.data[0].acf.flexible_components
        const post_component = responseData.data[0].acf.post_component
        let newComponents : any[] = []
        if(post_component){
          newComponents = [{
            acf_fc_layout: Object.keys(post_component)[0],
            ...responseData.data[0]
          }, ...newComponents]
        }
        if(flexible_components){
          newComponents = [...newComponents, ...flexible_components]
        }
        setPageComponents(newComponents)
      }
      return responseData;
    } catch (err) {
      setError(err)
      return { page_component_end_point_error: err }
    }
  }

  useEffect(() => {
    const prePage = async () => {
      try {
        const url = `${url_format}page-status/?pathname=${window.location.pathname.slice(1)}`
        const responseData = await checkPageStatus(url);
        setPageCode(responseData.statusCode);
        if(responseData.statusCode === 301 && window.location.pathname.slice(1) !== responseData.redrectTo){
          window.location.replace(`/${responseData.redrectTo !== '/' ? responseData.redrectTo : ''}`);
        } 
        setRedirectFinished(true);
      } catch (e) {
        console.log(e.status)
      }
    }
    
    setRedirectFinished(false);
    prePage();
  }, []);


  useEffect(()=>{
    fetchEndPoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

useEffect(()=>{
  if(settings){
    localStorage.setItem('globalSettings', JSON.stringify(settings));
  }
  
},[settings])



const scrollToSection = (sectionId : string) => {
    setTimeout(()=>{
      const rootElem = document.getElementById('root')
      if(rootElem){
        const section = document.getElementById(sectionId);
        if (section) {
          let top = section.offsetTop-(window.innerWidth*0.1)
          if(isMobile){
            top = section.offsetTop-(window.innerWidth*0.35)
          }
          rootElem.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      }
    },100)
  };

  
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scrollToSection(hash);
    }else{
      const rootElem = document.getElementById('root')
      if(rootElem){
        rootElem.scrollTo({top: 0});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash, window.location.pathname]);


  if(error && pageCode !== undefined){
    if (pageCode === 301 || pageCode === undefined) {
      return <LoadingStatic />;
    }
    
    return <ErrorScreen />;
  }


  if(pageComponents.length > 0){

    return (
      <>
          <Header />
          {pageComponents?.map((item : any, key : number) => {
            const Component = components[item.acf_fc_layout];
            if (!Component) {
              console.error(`Component "${item.acf_fc_layout}" not found`);
              return null;
            }
            return <Component key={key} {...item} />;
          })}
          <Footer />
          {snackbar?<Snackbar onClose={()=>setSnackbar(null)} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}} open autoHideDuration={6000}>
            <Alert onClose={()=>setSnackbar(null)} severity={snackbar.type} sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>:null}
      </>
    )

  }

  return <LoadingStatic />

}

export default Page;
