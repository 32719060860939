export const rectangleMaskPosition = (iconType: string) => {
    switch (iconType) {
      case 'BottomLeft':
        return {
          desktop: {
            left: 0,
            bottom: '-6%',
          },
          mobile: {
            left: 0,
            bottom: '-2%',
          },
        };
      case 'BottomRight':
        return {
          desktop: {
            right: 0,
            bottom: '-6%',
          },
          mobile: {
            right: 0,
            bottom: '-2%',
          },
        };
      case 'TopRight':
        return {
          desktop: {
            right: 0,
            top: '-6%',
          },
          mobile: {
            right: 0,
            top: '-2%',
          },
        };
      case 'TopLeft':
        return {
          desktop: {
            left: 0,
            top: '-6%',
          },
          mobile: {
            right: 0,
            top: '-2%',
          },
        };
  
      default:
        return {};
    }
  };