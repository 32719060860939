import { useState } from "react";
import { useRecoilState } from "recoil";
import { Box } from "@mui/material";
import { atomMap } from "../../atoms";
import MainButton from "../Button";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevronRight.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron_down.svg";
import { ReactComponent as PhoneIcon } from '../../assets/icons/phoneIcon.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import {
    Categories,
    NavItem,
    NavText,
    NavWrapper,
    Navigation,
    SubCategories,
    SubOption,
    CategoryMenu,
    CategoryContainer,
    InfoContent,
    InfoTitle,
    InfoContainer,
    ChevronMenu
} from "./styles";
import decodeHTMLEntities from "../../utils/functions/decodeHTMLEntities";
import { navItemsProps } from "./navItems";
import { NavMenu } from "../Header/styles";

function MobileHeaderSubSubOptions({ child_items }: { child_items: { url: string, title: string }[] }) {
    return (
        <SubCategories>
            {child_items.map((item, key) => (
                <SubOption key={key} to={item.url}>
                    {item.title}
                </SubOption>
            ))}
        </SubCategories>
    )
}

type MobileHeaderSubOptionsProps = {
    subOptions: {
        acf: {
            menu_icon: string;
            menu_description: string;
        };
        title: string;
        url: string;
        child_items: {
            url: string;
            title: string;
        }[]
    }[];
}

function MobileHeaderSubOptions({ subOptions }: MobileHeaderSubOptionsProps) {
    const [activeCategory, setActiveCategory] = useState<string | null>(null)


    return (
        <Categories>
            {subOptions.map(({ acf, title, child_items, url }, key: number) => (
                <CategoryContainer to={url}>
                    <CategoryMenu to={url} dangerouslySetInnerHTML={{ __html: title }} />
                    <ChevronMenu to={url}>
                        <ChevronRight/>
                    </ChevronMenu>
                </CategoryContainer>
            ))}
        </Categories>
    )
}

export default function MobileHeaderOptions() {
    const [activePage, setActivePage] = useState<string | null>(null)
    const [header] = useRecoilState(atomMap.header)
    const [settings] = useRecoilState(atomMap.settings)
    const [activeChevrons, setActiveChevrons] = useState<{ [key: number]: boolean }>({});
    const ButtonGroup = {
        ButtonVariant: "secondary",
        Button: {
            title: settings?.HeaderButton.title,
            url: settings?.HeaderButton.url || "",
            target: settings?.HeaderButton.target
        }
    }
    const handleIconClick = (item: any, key: any) => {
        setActiveChevrons(key)
        setActivePage(item.title === activePage ? null : item.title)
    }


    const _navigate = (url: string) => {
        window.open(url,'_blank')
    };

    return (
        <>
            <Navigation>
                {header.map((item: navItemsProps, key: number) => (
                    <NavWrapper key={key}>
                        <NavItem to={item.url}>
                            <NavText to={item.url}>{decodeHTMLEntities(item.title)}</NavText>
                            {activePage === item.title ?
                                null :
                                <ChevronMenu to={item.child_items ? '#' : item.url}>
                                <ChevronRight
                                    onMouseDown={item.child_items ? () => handleIconClick(item, key) : () => null}
                                />
                                </ChevronMenu>
                            }


                        </NavItem>
                        {item.child_items && activePage === item.title ? <MobileHeaderSubOptions subOptions={item.child_items} /> : null}
                    </NavWrapper>
                ))}
                <Box sx={{ padding: '13px 20px',paddingBottom: 0 }}>
                    <MainButton minWidth="100%" ButtonGroup={ButtonGroup} />
                </Box>
                <Box display="flex" flexDirection="column" gap="24px" marginTop="104px" paddingLeft="20px" paddingRight="20px">
                    <InfoContainer sx={{ display: 'flex', gap: '16px' }}>
                        <PhoneIcon />

                        <Box>
                            <InfoTitle>Call us</InfoTitle>
                            {
                                settings?.BrandPhoneNumbers.map((item: any, index: number) =>
                                    <InfoContent onClick={()=>_navigate(`tel:${item.BPhoneNumber}`)} key={index}>{item.BPhoneName} {item.BPhoneNumber}</InfoContent>
                                )
                            }
                        </Box>

                    </InfoContainer>
                    <InfoContainer sx={{ display: 'flex', gap: '16px' }}>
                        <EmailIcon />

                        <Box>
                            <InfoTitle>Talk to us</InfoTitle>
                            <InfoContent onClick={()=>_navigate(`mailto:${settings?.BrandEmail}`)}>{settings?.BrandEmail}</InfoContent>
                        </Box>

                    </InfoContainer>


                </Box>
            </Navigation>

        </>
    )
}