import React from 'react';
import {Box, Typography} from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';

interface ServiceChipProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const Chip: React.FC<ServiceChipProps> = ({ label, selected, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
        backgroundColor: selected ? '#274BA1' : 'transparent',
        borderRadius: '8px',
        border: selected ? '1px solid #274BA1' : '1px solid rgba(255, 255, 255, 0.10)',
        color: '#EEE7DC',
        padding: '4px 12px',
        cursor: 'pointer'
    }}
  >
    <Typography 
    sx={{
      fontSize: pxToRem(14),
      fontFamily: 'Public Sans',
      fontWeight: 400,
      lineHeight: pxToRem(22),
      color: '#EEE7DC',
    }}
    dangerouslySetInnerHTML={{__html: label}}
    />
      
  </Box>
);

export default Chip;
