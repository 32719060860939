import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import MainButton from '../Button';
import { ReactComponent as Anchor } from '../../assets/icons/anchor.svg';
import { 
    Heading, 
    Description, 
    Container, 
    ContentWrapper, 
    ButtonContainer,
    MaskWrapper,
    LeftContainer,
    RightContainer } from './styles';



interface CTABannerProps {
    CBTitle: string;
    CBMask: boolean;
    CBContent: string;
    ButtonGroup: ButtonGroupProps
}
export default function CTABanner({ CBTitle, CBMask, CBContent,ButtonGroup }: CTABannerProps) {
    const isMobile = useResponsive('down', 'md')
    return (
        <Container className='CTABanner'>
            {/* {CBMask && !isMobile &&
                <MaskWrapper
                initial={{ rotate: -15 }}
                animate={{rotate: [15, -15, 15]}}
                transition={{duration: 2,ease: "linear",repeat: Infinity,repeatType: "loop"}}
                >
                    <Anchor />
                </MaskWrapper>

            } */}
            <ContentWrapper>
                <LeftContainer>
                    <Heading 
                    sx={[globalSettings.FontProperty.TextBold]}
                    dangerouslySetInnerHTML={{__html:CBTitle}}
                    />
                </LeftContainer>
               <RightContainer>
                    <Description
                     dangerouslySetInnerHTML={{__html:CBContent}}
                    />
                    <ButtonContainer>
                        <MainButton  ButtonGroup={ButtonGroup} />
                    </ButtonContainer>
               </RightContainer>
            </ContentWrapper>
        </Container>
    )
}
