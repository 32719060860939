import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import pxToRem from "src/utils/functions/pxToRem";
import globalSettings from "../../constants/globalSettings";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevronRight.svg";




export const Categories = styled(Box)(({ theme }) => ({
   
  }));

  export const CategoryMenu = styled(Link)(({ theme }) => ({
    all: 'unset',
    fontSize: pxToRem(14),
    fontWeight: 400,
    fontFamily: 'Public Sans',
    lineHeight: pxToRem(22),
    paddingLeft: '20px'
  }));

  export const ChevronMenu = styled(Link)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   '& svg': {
    height: pxToRem(16),
    width: pxToRem(16),
  },
  '& svg path': {
    // stroke: "#101F43",
  },
 
  }));

  export const CategoryContainer = styled(Link)(({ theme }) => ({
    all: 'unset',
    padding: '13px 20px',
    paddingRight: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover':{
      backgroundColor: '#F3F4F6'
    },
    '&:hover svg path': {
      stroke: globalSettings.Colors.red
    },
  }));

  export const SubCategories = styled(Box)(({ theme }) => ({
   width: "100%",
  }));




export const Navigation = styled(Box)(({ theme }) => ({
    minHeight: '100%',
    paddingBottom: pxToRem(114),
    display: 'flex',
    flexDirection: 'column',
  }));

  export const NavWrapper = styled(Box)(({ theme }) => ({
    paddingLeft: '16px',
    paddingRight: '16px'
  }));



export const InfoContent = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(14),
    fontFamily: 'Public Sans',
    fontWeight: 400,
    lineHeight: pxToRem(22),
    opacity: 0.7,
    color: globalSettings.Colors.primary,
    '&:hover':{
      color: globalSettings.Colors.red
    },
   
  }));


  export const InfoContainer = styled(Box)(({ theme }) => ({
    dispaly: 'flex',
    gap: pxToRem(16),
    '& svg': {
      height: pxToRem(28),
      width: pxToRem(28),
    },
    '& svg path': {
      stroke: "#101F43",
      strokeWidth: 0
    },
  }));

  export const InfoTitle = styled(Typography)(({ theme }) => ({
    lineHeight: pxToRem(28),
    color: globalSettings.Colors.primary,
    '& p': {
      display: 'inline',
      margin: 0,
      padding: 0,
    },
    fontSize: pxToRem(18),
    fontWeight: 600,
    width: '100%',
    marginBottom: pxToRem(4),
    fontFamily: 'Barlow'
  }));

export const NavItem = styled(Link)(({ theme }) => ({
    all: 'unset',
    padding: '13px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      height: pxToRem(16),
      width: pxToRem(16),
    },
    '& svg path': {
      // stroke: "#101F43",
    },
    '&:hover':{
      backgroundColor: '#F3F4F6'
    },
    '&:hover svg path': {
      stroke: globalSettings.Colors.red
    },
  }));

export const NavText = styled(Link)(({ theme }) => ({
    all: 'unset',
    fontSize: pxToRem(14),
    fontWeight: 400,
    fontFamily: 'Public Sans',
    lineHeight: pxToRem(22),
  }));


export const SubOption = styled(Link)`
    all: unset;
    line-height: 1.3;
    color: rgba(16, 46, 84, 0.66);
    font-size: 3.6vw;
    cursor: pointer;
    display: block;
    margin-bottom: 10.25vw;
    &:last-of-type {
        margin-bottom: 0;
    }
`


