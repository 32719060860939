import React from 'react'
import { Box, styled } from '@mui/material'
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { width } from '@mui/system';

const IconWrapper = styled(Link)`
    cursor: pointer;
    @media (max-width: 768px) {
        svg {
            height: auto;
        }
        &:last-of-type svg {
            width: 6vw;
        }
    }`

const Container = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    padding: '8px',
    display: 'grid',
    placeItems: 'center',
    transition: 'transform 0.3s ease',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '50%',
        transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('md')]: {
        'svg':{
            height: 'auto'
        }
    },

}));

interface IconProps{
    url: string;
    icon: string;
}
export default function Icon({url,icon}: IconProps) {
    const isMobile = useResponsive("down","md")
    if( url !== "") {
        const handleNavigate = () => {
            window.open(url, '_blank');
          };
          
        return (
            <Container onClick={handleNavigate}>
                <Iconify style={{height: '20px', width: '20px'}} icon={icon} color={globalSettings.Colors.red}/>
            </Container>
        )
    } 
    return null;
}
