import { Box } from '@mui/material'
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {useNavigate} from 'react-router-dom'
import { fetchDataFromAPI, url_format } from 'src/atoms/apiConfig';
import { modalAtom } from 'src/atoms/modal';
import useResponsive from 'src/hooks/useResponsive';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import Button, { SecondaryButton1 } from 'src/components/ButtonBackup';
import Image from 'src/components/Image/Image';
import Icon from './components/Icon';
import { atomMap } from '../../atoms';
import NavigationLayout from './components/NavigationLayout';
import {
  Container,
  IconContainer,
  BlueLine,
  ContentWrapper,
  Content,
  LeftContainer,
  RightContainer,
  TagLineContent,
  TagLineTitle,
  Copyright
} from './styles';


const {
  REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };


const ICONS = [
  {
    url: "BrandFacebook",
    icon: "ant-design:facebook-filled"
  },
  {
    url: "BrandInstagram",
    icon: "mdi:instagram"
  },
  {
    url: "BrandLinkedin",
    icon: "mdi:linkedin"
  },
  {
    url: "BrandTwitter",
    icon: "mdi:twitter"
  }
]
export default function Footer() {
  const [footer] = useRecoilState(atomMap.footer)
  const [settings] = useRecoilState(atomMap.settings)
  const isMobile = useResponsive("down", "md")
  const year = new Date().getFullYear()
  const navigate = useNavigate();

  if (settings && footer) {
    const {FooterCopyright, FooterTaglineTitle,FooterTaglineContent,BrandLogoSecondary} = settings
    return (
      <Container id="footer">
        <ContentWrapper>
          <Image
            onClick={()=>navigate('/')}
            src={`https://${REACT_APP_CMS_HOST}${BrandLogoSecondary}`}
            sx={{
              width: isMobile ? pxToRem(188) : pxToRem(304),
              height: isMobile ? pxToRem(70) : pxToRem(115),
              cursor:'pointer'
            }}
          />
          <Content>
            <LeftContainer>

              <TagLineTitle>{FooterTaglineTitle}</TagLineTitle>
              <TagLineContent>{FooterTaglineContent}</TagLineContent>

              <IconContainer>
                {ICONS.map((icon, index) =>
                  <Icon key={index} url={settings[icon.url]} icon={icon.icon} />
                )}
              </IconContainer>

            </LeftContainer>

            <RightContainer>
              <NavigationLayout navigationData={footer} />
            </RightContainer>
          </Content>
          <BlueLine />
          <Copyright>©{year}. {FooterCopyright}</Copyright>
        </ContentWrapper>
      </Container>
    )
  }
  return null
}
