import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import Image from 'src/components/Image';
import { useState } from 'react';



const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

const Container = styled(Box)(({ theme }) => ({
    maxWidth: pxToRem(291),
    minHeight: '100%',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.up('xl')]: {
        minWidth: '100%'
    },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    padding: `${pxToRem(42)} ${pxToRem(40)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: `${pxToRem(37)} ${pxToRem(40)}`,
    },
}));


const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(28),
    marginTop: pxToRem(20),
    color: globalSettings.Colors.secondary,
    textAlign: 'center',
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(18),
        marginBottom: pxToRem(4),
        width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',

    },
}));

const Description = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(28),
    fontWeight: 400,
    fontFamily: 'Public Sans',
    textAlign: 'center',
    color: '#919EAB',
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(14),
        width: '100%',
        lineHeight: pxToRem(22),
        textAlign: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',
    },
}));

const ImageBox = styled(Box)(({ theme }) => ({
}));


interface CardProps {
    CTItemIcon: string;
    CTItemTitle: string;
    CTItemDescription: string
}
export default function Card({ CTItemTitle, CTItemIcon, CTItemDescription }: CardProps) {
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <Container className="CapabilityCard" onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
            <ContentWrapper>
                <ImageBox sx={{
                    backgroundColor: onHover ? globalSettings.Colors.red : 'transparent',
                    width: '72px',
                    height: '72px',
                    borderRadius: '50%',
                    display: 'grid',
                    placeItems: 'center'
                }}>
                    <Image
                        src={`https://${REACT_APP_CMS_HOST}${CTItemIcon}`}
                        sx={{
                            width: '48px',
                            height: '48px'
                        }}
                    />
                </ImageBox>

                <Title sx={[globalSettings.FontProperty.TextSemiBold]}>{CTItemTitle}</Title>
                <Description dangerouslySetInnerHTML={{ __html: CTItemDescription }} />
            </ContentWrapper>
        </Container>
    )
}
