import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { rectangleMaskPosition } from 'src/theme/rectangleMaskPosition';
import pxToRem from 'src/utils/functions/pxToRem';


type RectangleProps = {
    backgroundColor: string;
    positiontype: string;
  };
  
  export const Rectangle = styled(Box)<RectangleProps>(
    ({ theme, backgroundColor, positiontype }) => ({
      ...rectangleMaskPosition(positiontype).desktop,
      backgroundColor,
      width: pxToRem(241),
      height: pxToRem(130),
      position: 'absolute',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        width: pxToRem(130),
        height: pxToRem(42),
        ...rectangleMaskPosition(positiontype).mobile,
      },
      [theme.breakpoints.between('md','lg')]: {
        top: positiontype === "TopLeft" ? '-3%' : 'initial',
        bottom: positiontype === "BottomRight" ? '-3% !important' : 'initial'
      },
      
    })
  );


export default function RectangleMask({backgroundColor, positiontype}: RectangleProps) {
  return (
    <Rectangle backgroundColor={backgroundColor} positiontype={positiontype}/>
  )
}
