import {ReactComponent as Anchor} from '../assets/icons/anchor.svg'
import {ReactComponent as SingleAnchor} from '../assets/icons/single-anchor.svg'
import {ReactComponent as Helm} from '../assets/icons/helm.svg'
import {ReactComponent as Seamew} from '../assets/icons/seamew.svg'

const getIcon = (icon : string) => {
    switch (icon) {
        case 'Anchor':
            return <SingleAnchor />
        case 'Helm':
            return <Helm />
        case 'Sea Mew':
            return <Seamew/>
        default:
            return null
    }
}
export default getIcon