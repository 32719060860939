import { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import getIcon from 'src/utils/getIcon';
import { FONT_COLORS } from 'src/theme/fontColors';
import getIconAnimation from 'src/utils/getIconAnimation';
import { Container, ContentWrapper, MaskWrapper } from './styles';
import Card from './components/Card';
import RectangleMask from '../RectangleMask';

interface MICBItemsProps {
    MICBItemContent: string;
    MICBItemImage: string;
    MICBItemTitle: string
}

interface MultiImageContentBlockProps {
    MICBItems: MICBItemsProps[];
    MICBItemsBgColour: string;
    MICBItemsMask: string;
    MICBItemsMaskPosition: string[]
}


export default function MultiImageContentBlock({ MICBItems, MICBItemsBgColour, MICBItemsMask, MICBItemsMaskPosition }: MultiImageContentBlockProps) {
    const isMobile = useResponsive('down', 'md')
    const [color, setColor] = useState("")


    useEffect(() => {
        setColor(FONT_COLORS[MICBItemsBgColour as "#0F1F43" | "#EEE7DC" | "#274BA1" | "#F6F3ED"] || "#F6F3ED")
    }, [MICBItemsBgColour])

    return (
        <Container backgroundColor={MICBItemsBgColour} className='MultiImageContentBlock'>
            {MICBItemsMaskPosition.map((item,index)=>
                <RectangleMask key={index} positiontype={item} backgroundColor={MICBItemsBgColour}/>
            )}  
            <ContentWrapper>
            {!isMobile &&
                <MaskWrapper
                    icontype={MICBItemsMask}
                    iconcolor={color}
                    initial={{ rotate: 0, x: 0 }}
                    animate={getIconAnimation(MICBItemsMask)}
                >
                    {getIcon(MICBItemsMask)}
                </MaskWrapper>
            }
                {
                    MICBItems.map((item, index) =>
                        <Card
                            {...item}
                            fontColor={color}
                            key={index} />)
                }
            </ContentWrapper>
        </Container>
    )
}
