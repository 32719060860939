const REM = 0.0625;

export default function pxToRem(px: number) {
  // Suggestion 1: The function should validate the input 'px' to ensure it is a positive number.
  if (px <= 0) {
    throw new Error('Input must be a positive number.');
  }

  const rem = px * REM;
  return `${rem}rem`;
}
