import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

const iconStyling = (iconType: string) => {
  switch (iconType) {
    case 'Helm':
      return {
        desktop: {
          height: pxToRem(944),
          width: pxToRem(944),
          right: '-7%',
          top: '104px',
        },
        mobile: {
          height: pxToRem(438),
          width: pxToRem(452),
          right: '-27%',
          top: '70px',
        },
      };
    case 'Anchor':
      return {
        desktop: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: '-27%',
          top: '-1%',
        },
        mobile: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: 0,
          top: 0,
        },
      };
    case 'Sea Mew':
      return {
        desktop: {
          height: pxToRem(415),
          width: pxToRem(772),
          left: '-10%',
          top: '25%',
        },
        mobile: {
          height: pxToRem(185),
          width: pxToRem(343),
          right: '-2%',
          top: '1%',
        },
      };
    default:
      return {};
  }
};

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.secondaryLight,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: pxToRem(globalSettings.Container.xl),
  paddingTop: pxToRem(216),
  position: 'relative',
  zIndex: 99,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(40),
    padding: 0,
    paddingTop: pxToRem(120),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: pxToRem(98)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(20),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)} 0`,
  },
}));

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: pxToRem(140),
  justifyContent: 'space-between',
  gap: pxToRem(180),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: 0,
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.md)}`,
    paddingTop: pxToRem(48),

  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    gap: pxToRem(20),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    gap: pxToRem(150),
  },
}));


export const LeftContainer = styled(Box)(({ theme }) => ({
  width: '25%',
  display: 'flex',

  flexDirection: 'column',
  gap: pxToRem(24),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: `${pxToRem(80)} ${pxToRem(globalSettings.Container.md)} 0`,
  },
  [theme.breakpoints.between('md','lg')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr)) !important',
    width: '100%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '40%',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  width: '940px',
  padding: "80px 144px 120px 144px",
  display: 'flex',
  alignItems: 'center',
  backgroundColor: globalSettings.Colors.primary,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: "56px 16px"
  },
  [theme.breakpoints.between('md','lg')]: {
    width: '100%',
    padding: "80px 30px 80px 30px",
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(76),
  lineHeight: "120%",
  color: globalSettings.Colors.primary,
  width: '55%',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    lineHeight: pxToRem(42),
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: pxToRem(48),
    width: '100%',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: pxToRem(56),
  },
}));



type MaskWrapperProps = {
  iconcolor: string;
  icontype: string;
};
export const MaskWrapper = styled(motion(Box))<MaskWrapperProps>(
  ({ theme, iconcolor, icontype }) => ({
    ...iconStyling(icontype).desktop,
    position: 'absolute',
    backgroundColor: 'transparent',
    opacity: 0.03,
    zIndex: 1,
    '& svg': {
      height: '100%',
      width: '100%',
    },
    '& svg path': {
      stroke: iconcolor,
      fill: iconcolor,
    },
    [theme.breakpoints.down('md')]: {
      ...iconStyling(icontype).mobile,
    },
    [theme.breakpoints.between('md', 'lg')]: {},
  })
);