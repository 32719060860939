import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";

interface CounterProps {
  from: number;
  to: number;
}

const CountUp: React.FC<CounterProps> = ({ from = 0, to }) => {
  const [currentValue, setCurrentValue] = useState(from);
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    const node = nodeRef.current;
    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const animation = setInterval(() => {
      setCurrentValue((prevValue) => {
        const increment = Math.ceil((to - prevValue) / 10);
        return prevValue + increment;
      });
    }, 100);

    if (currentValue >= to) {
      clearInterval(animation);
    }
    // eslint-disable-next-line
    return () => clearInterval(animation);
  }, [to, isInView, currentValue]);

  const formatNumber = (num: number): string => {
    if (num >= 1000 && num < 1000000) {
      const formatted = (num / 1000).toFixed(2);
      const [integerPart, decimalPart] = formatted.split('.');
  
      if (decimalPart === '00') {
        return `${integerPart}k`;
      } 
      return `${formatted}k`;
      
    }
  
    return num.toString();
  };
  

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
    >
      {/* {Math.round(currentValue).toLocaleString()} */}
      {formatNumber(currentValue)}
    </motion.div>
  );
};

export default CountUp;
