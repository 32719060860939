import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import { ReactComponent as LocationSVG } from '../../../assets/icons/location.svg';


export const LocationText = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontFamily: 'Public Sans',
    minWidth: '20px',
    color: globalSettings.Colors.primary,
    fontWeight: 400,
    opacity: 0.7,
    '&:hover':{
        color: globalSettings.Colors.red,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(16),
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',
    },
}));

export const SvgContainer = styled(Typography)(({ theme }) => ({
    '& svg': {
        height: pxToRem(24),
        width: pxToRem(24),
    },
    '& svg path': {
        stroke: globalSettings.Colors.red,
        fill: globalSettings.Colors.red,
        strokeWidth: '0.5px'
      },
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.between('md', 'lg')]: {
    },
}));


export const LocationContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    '& svg': {
        height: pxToRem(24),
        width: pxToRem(24),
    },
    '& svg path': {
        stroke: globalSettings.Colors.red,
        fill: globalSettings.Colors.red,
      },
    [theme.breakpoints.down('md')]: {
        // marginBottom: pxToRem(24),
    },
}));

interface LocationProps {
    title: string;
    index: number;
    url: string;
}
export default function Location({ title, index,url }: LocationProps) {


    const _navigate = () => {
        const link = `http://maps.google.com/?q=${url}`
        window.open(link,'_blank')
    };
    return (
        <Grid item lg={4} xs={6} key={index} sm={4} 
        sx={{cursor: 'pointer'}}>
            <LocationContainer onClick={()=>_navigate()}>
                <SvgContainer>
                    <LocationSVG />
                </SvgContainer>
                <LocationText>{title}</LocationText>
            </LocationContainer>
        </Grid>
    )
}
