import { Box, Grid } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import getIconAnimation from 'src/utils/getIconAnimation';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import MainButton from '../Button';
import Card from './components/Card';
import { ReactComponent as SubtitleIcon } from '../../assets/icons/anchorIcon.svg';
import { ReactComponent as SingleAnchorSVG } from '../../assets/icons/singleAnchor.svg';
import {
    Heading,
    Content,
    Container,
    ContentWrapper,
    ButtonContainer,
    SubTitle,
    SubTitleContainer,
    LeftContainer,
    RightContainer,
    GridItem,
    MaskWrapper
} from './styles';


interface CTItemsProps {
    CTItemIcon: string;
    CTItemTitle: string;
    CTItemDescription: string
}


interface ServiceTilesProps {
    STSubtitle: string;
    STTitle: string;
    STContent: string;
    ButtonGroup: ButtonGroupProps
    STServices: number[]
}
export default function ServiceTiles({ STSubtitle, STTitle, STContent, ButtonGroup, STServices }: ServiceTilesProps) {
    const isMobile = useResponsive('down', 'lg')
    return (
        
            <Container className='ServiceTiles'>
                <MaskWrapper
                initial={{ rotate: 0, x: 0 }}
                animate={getIconAnimation("Anchor")}
            >
                <SingleAnchorSVG />
            </MaskWrapper>
                <ContentWrapper>
                    <LeftContainer>
                        <SubTitleContainer>
                            <SubtitleIcon />
                            <SubTitle>{STSubtitle}</SubTitle>
                        </SubTitleContainer>
                        <Heading
                            sx={[globalSettings.FontProperty.TextBold]}
                            dangerouslySetInnerHTML={{ __html: STTitle }}
                        />
                        <Content
                            dangerouslySetInnerHTML={{ __html: STContent }}
                        />
                        <ButtonContainer>
                            <MainButton ButtonGroup={ButtonGroup} />
                        </ButtonContainer>
                    </LeftContainer>


                    <RightContainer>
                    <Grid container spacing={isMobile ? 3 : 0}>
                             {   STServices.map((service,index) =>
                                     <GridItem 
                                     key={index}
                                     item 
                                     xs={12}
                                     sm={6} 
                                     md={6} 
                                     lg={6} 
                                     xl={6}
                                     paddingLeft={index % 2 !== 0 ? '46px' : 0}
                                     paddingTop={index % 2 !== 0 ? '60px' : 0}
                                     >
                                        <Card key={index} id={service} />
                                    </GridItem>
                                )
                            }
                    </Grid>
                    </RightContainer>
                </ContentWrapper>
            </Container>
     
    )
}
