import globalSettings from "src/constants/globalSettings_backup";
import globalSettingsNew from "src/constants/globalSettings";
import useResponsive from "src/hooks/useResponsive";
import { ButtonLinkStyle, ButtonStyle } from "./styles";
import {ReactComponent as LeftArrow} from '../../assets/icons/leftArrow.svg'
import {ReactComponent as RightArrow} from '../../assets/icons/rightArrow.svg'
import {ReactComponent as ChevronRight} from '../../assets/icons/chevronRight.svg'

const useButtonPadding = (icon : any, padding?: {desktop?: string, mobile?: string}) => {

    const defaults = {
        textButtonPadding: '1.65vw 2.5vw',
        textButtonPaddingMobile: '7vw 12vw',
        iconButtonPadding: '1.25vw 2.25vw',
        iconButtonPaddingMobile: '5.25vw 9.25vw',
    }

    const isMobile = useResponsive('down', 'md');

    let result : string | undefined = defaults.textButtonPadding;

    if(padding){
        result = padding.desktop;
        if(isMobile){
            result = padding.mobile
        }
    }else if (isMobile) {
        if (icon) {
            result = defaults.iconButtonPaddingMobile;
        } else {
            result = defaults.textButtonPaddingMobile;
        }
    } else if (icon) {
        result = defaults.iconButtonPadding;
    }
  
    return result;

};

const useButtonFontSize = (fontSize ?: {desktop?: string, mobile?: string}) => {

    const defaults = {
        buttonFontSize: '0.8vw',
        buttonFontSizeMobile: '3.8vw'
    }

    const isMobile = useResponsive('down', 768);

    let result : string | undefined = defaults.buttonFontSize;

    if(fontSize){
        result = fontSize.desktop
        if(isMobile){
            result = fontSize.mobile
        }
    }else if (isMobile) {
        result = defaults.buttonFontSizeMobile
    }
  
    return result;

};


const useButtonSvgSize = () => {

    const defaults = {
        desktop: '1vw',
        mobile: '4vw'
    }

    const isMobile = useResponsive('down', 768);

    let result = defaults.desktop;

    if (isMobile) {
        result = defaults.mobile
    }
  
    return result;

};

const colors = {
    primary: globalSettings.Colors.primary,
    white: globalSettings.Colors.white,
    textPrimary: globalSettings.FontProperty.TextColorPrimary.color,
    textWhite: globalSettings.FontProperty.TextColorWhite.color
}

type ButtonProps = {
    button_group?: {
        button: {
            target?: string;
            title: string;
            url?: string;
        },
        button_style?: string;
    },
    fontSize?: string;
    height?: string;
    width?: string;
    rounded?: 0 | 1;
    isDisabled?: boolean;
    icon?: string;
    type?: "link" | "function";
    onClick?: () => void;
    title?: string
    lineheight?: number;
    padding?: string;
    button_settings?: {
        padding?: {desktop?: string, mobile?: string};
        fontSize?: {desktop?: string, mobile?: string};
    }
}

const getIcon = (icon : string) => {
    switch (icon) {
        case 'leftArrow':
            return <LeftArrow />
        case 'rightArrow':
            return <RightArrow />
        case 'chevronRight':
            return <ChevronRight/>
        default:
            return null
    }
}


export function PrimaryButton({isDisabled, icon, rounded,title, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    if(button_group?.button?.url){
        return (
            <ButtonLinkStyle padding={padding} to={button_group?.button?.url} target={button_group?.button?.target} fontSize={fontSize} height={height} width={width} bgcolor={colors.primary} textcolor={colors.white} bordercolor={colors.primary} rounded={rounded}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonLinkStyle>
        )
    }
    return (
        <ButtonStyle padding={padding} onClick={onClick} fontSize={fontSize} height={height} width={width} bgcolor={colors.primary} textcolor={colors.white} bordercolor={colors.primary} rounded={rounded} disabled={isDisabled}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonStyle>
    )
}

export function PrimaryWhiteText({isDisabled, icon,title, rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    if(button_group?.button?.url){
        return (
            <ButtonLinkStyle padding={padding} to={button_group?.button?.url} target={button_group?.button?.target} fontSize={fontSize} height={height} width={width} bgcolor='transparent' textcolor={colors.textWhite} bordercolor={colors.white} rounded={rounded}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonLinkStyle>
        )
    }
    return (
        <ButtonStyle padding={padding} onClick={onClick} fontSize={fontSize} height={height} width={width} bgcolor='transparent' textcolor={colors.textWhite} bordercolor={colors.white} rounded={rounded} disabled={isDisabled}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonStyle>
    )
}

export function PrimaryWhite({isDisabled, icon,title, rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    if(button_group?.button?.url){
        return (
            <ButtonLinkStyle padding={padding} to={button_group?.button?.url} target={button_group?.button?.target} fontSize={fontSize} height={height} width={width} bgcolor={colors.white} textcolor={colors.textPrimary} rounded={rounded}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonLinkStyle>
        )
    }
    return (
        <ButtonStyle padding={padding} onClick={onClick} fontSize={fontSize} height={height} width={width} bgcolor={colors.white} textcolor={colors.textPrimary} rounded={rounded} disabled={isDisabled}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonStyle>
    )
}

export function PrimaryWhiteLink({isDisabled, icon,title, rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle padding={padding}  fontSize={fontSize} height={height} width={width} bgcolor={colors.white} textcolor={colors.textPrimary} rounded={rounded} disabled={isDisabled}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonStyle>
    )
}

export function PrimaryText({isDisabled, icon, rounded,title, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle padding={padding} activecolor="#ffffff" fontSize={fontSize} height={height} width={width} bgcolor='transparent' textcolor={colors.textPrimary} bordercolor={colors.primary} rounded={rounded} disabled={isDisabled}>{button_group?.button?.title || null}{icon ? getIcon(icon) : null}</ButtonStyle>
    )
}

export function PrimaryButton1({isDisabled, icon, rounded, height, width, fontSize,title, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle 
        opacity="99" 
        fontSize={useButtonFontSize(button_settings?.fontSize)} 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', lineHeight: 0, padding: useButtonPadding(icon, button_settings?.padding)}}
        sx={{'svg': {height: useButtonSvgSize(), width: useButtonSvgSize(), padding: '0'}}}
        height={height} 
        width={width} 
        bgcolor={colors.primary} 
        textcolor={colors.textWhite} 
        rounded={rounded} 
        disabled={isDisabled}
        onClick={onClick}
        >
            {button_group?.button?.title || title}{icon ? getIcon(icon) : null}
        </ButtonStyle>
    )
}

export function PrimaryButton2({isDisabled, icon, rounded,title, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle 
        opacity="CC" 
        activecolor="#B2D8FB" 
        fontSize={useButtonFontSize(button_settings?.fontSize)} 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', lineHeight: 0, padding: useButtonPadding(icon, button_settings?.padding)}}
        sx={{'svg': {height: useButtonSvgSize(), width: useButtonSvgSize(), padding: '0'}}}
        height={height} 
        width={width} 
        bgcolor={globalSettingsNew.Colors.red} 
        textcolor={globalSettingsNew.Colors.white} 
        rounded={0} 
        disabled={isDisabled}
        onClick={onClick}
        >
            {button_group?.button?.title || title}{icon ? getIcon(icon) : null}
        </ButtonStyle>
    )
}

export function SecondaryButton1({isDisabled, icon,title, rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle 
        opacity="38" 
        activecolor="#66B2F6" 
        fontSize={useButtonFontSize(button_settings?.fontSize)} 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', lineHeight: 0, padding: useButtonPadding(icon, button_settings?.padding)}}
        sx={{'svg': {height: useButtonSvgSize(), width: useButtonSvgSize(), padding: '0'}}}
        height={height} 
        width={width} 
        bgcolor='transparent' 
        textcolor={colors.textWhite} 
        bordercolor={colors.white} 
        rounded={rounded}
        disabled={isDisabled}
        onClick={onClick}
        >
            {button_group?.button?.title || title}{icon ? getIcon(icon) : null}
        </ButtonStyle>
    )
}

export function SecondaryButton2({isDisabled, icon, title,rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle sx={{
            "&:hover": {
            backgroundColor: "#FFFFFF", 
            opacity: "60%"
            },
            "&:active":{
                backgroundColor:"#FFFFFF",
                opacity: "100%"
            },
            'svg': {height: useButtonSvgSize(), width: useButtonSvgSize(), padding: '0'}
          }} 
        fontSize={useButtonFontSize(button_settings?.fontSize)} 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', lineHeight: 0, padding: useButtonPadding(icon, button_settings?.padding)}}
        height={height} 
        width={width} 
        bgcolor='transparent' 
        textcolor={colors.textPrimary} 
        bordercolor={colors.primary} 
        rounded={rounded} 
        disabled={isDisabled}
        onClick={onClick}
        >
            {button_group?.button?.title || title}{icon ? getIcon(icon) : null}
        </ButtonStyle>
    )
}

export function SecondaryButton3({isDisabled, icon,title, rounded, height, width, fontSize, type, onClick, button_group, lineheight, padding, button_settings} : ButtonProps) {
    return (
        <ButtonStyle sx={{
            "&:hover": {
            backgroundColor: "#EFF9FE", 
            },
            "&:active":{
                backgroundColor:"#CCE5FC",
                opacity: "100%"
            },
            'svg': {height: useButtonSvgSize(), width: useButtonSvgSize(), padding: '0'}
          }} 
        activecolor="yellow"  
        opacity="CC"
        fontSize={useButtonFontSize(button_settings?.fontSize)} 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', lineHeight: 0, padding: useButtonPadding(icon, button_settings?.padding)}}
        height={height} 
        width={width} 
        bgcolor='transparent' 
        textcolor={colors.textPrimary} 
        bordercolor={colors.primary} 
        rounded={rounded} 
        disabled={isDisabled}
        onClick={onClick}
        >
            {button_group?.button?.title || title}{icon ? getIcon(icon) : null}
        </ButtonStyle>
    )
}

export default function Button({button_group,title, fontSize, height, width, rounded, isDisabled, icon, type = "link", onClick, lineheight, padding, button_settings} : ButtonProps) {
    const style = button_group?.button_style
    const newFontSize = useButtonFontSize(button_settings?.fontSize)
    const newPadding = useButtonPadding(icon, button_settings?.padding)
    switch (style) {
        case 'PrimaryButton':
            return (
                <PrimaryButton padding={newPadding} button_group={button_group} type={type} onClick={onClick} fontSize={newFontSize}  height={height} width={width} rounded={rounded} isDisabled={isDisabled} icon={icon} />
            )
        case 'PrimaryWhiteButton':
            return (
                <PrimaryWhite padding={newPadding} button_group={button_group} type={type} onClick={onClick} fontSize={newFontSize}  height={height} width={width} rounded={rounded} isDisabled={isDisabled} icon={icon} />
            )
        case 'PrimaryTextWhiteButton':
            return (
                <PrimaryWhiteText padding={newPadding} button_group={button_group} type={type} onClick={onClick} fontSize={newFontSize} height={height} width={width} rounded={rounded} isDisabled={isDisabled} icon={icon} />
            )
        default:
            return (
                <PrimaryWhite padding={newPadding} button_group={button_group} type={type} onClick={onClick} fontSize={newFontSize} height={height} width={width} rounded={rounded} isDisabled={isDisabled} icon={icon} />
            )
    }
}