import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';


type ContainerProps = {
  backgroundColor: string;
};
export const Container = styled(Box)<ContainerProps>(({ theme,backgroundColor }) => ({
  backgroundColor,
  position: 'relative',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${pxToRem(140)} ${pxToRem(globalSettings.Container.xl)}`,
  position: 'relative',
  zIndex: 99,
  gap: pxToRem(320),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(56),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    gap: pxToRem(100),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.lg)}`,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));


export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(52),
  lineHeight: pxToRem(64),
  marginBottom: pxToRem(134),
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    lineHeight: pxToRem(42),
    marginBottom: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: pxToRem(24),
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  marginBottom: pxToRem(32),
  fontFamily: 'Public Sans',
  maxWidth: '95%',
  '& h5, h6': {
    fontSize: pxToRem(24),
    opacity: 1,
    lineHeight: '120%',
    fontWeight: 500,
  },
  ' h6:first-of-type,h5:first-of-type': {
    marginTop: 0
  },
  '& p': {
    marginTop: 0,
    opacity: 0.7,
    lineHeight: pxToRem(24),
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginBottom: pxToRem(24),
    '& h5,h6': {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(24),
      maxWidth: '100%',
      fontWeight: 500,
    },
    '& p': {
     maxWidth: '100%'
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '100%',
    '& h5,h6,p': {
    
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.between('lg', 'xl')]: {
   
    '& p,h5,h6': {
     maxWidth: '100%'
    },
  },
}));


export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '52%',
  [theme.breakpoints.down('md')]: {
   width: '100%'
  },
  [theme.breakpoints.between('md', 'lg')]: {
   width: '100%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '53%'
   },
}));


export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down('md')]: {
    height: pxToRem(300)
  },
  [theme.breakpoints.down('sm')]: {
    height: pxToRem(96)
  },
  [theme.breakpoints.between('md', 'lg')]: {
  },
}));

export const RightSideWrapper = styled(Box)(({ theme }) => ({
  width: '58%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '47%'
   },
}));


export const SubTitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: pxToRem(32),
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    marginBottom: pxToRem(24),
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(18),
  textTransform: 'uppercase',
  fontWeight: 700,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  color: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
