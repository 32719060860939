import { Box, styled } from "@mui/material"
import globalSettings from "src/constants/globalSettings"
import { PrimaryButton2 } from "../ButtonBackup"

export const Container = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: white;
    background-color: ${globalSettings.Colors.primary};
    flex-flow: column;
`

export const Error404 = styled(Box)`
    font-size: 3vw;
    font-weight: 600;
    margin-bottom: 2vw;
    color: ${globalSettings.Colors.secondaryLight};
    @media (max-width: 768px) {
        font-size: 7vw;
        margin-bottom: 8vw;
    }
`

export default function ErrorScreen () {
    return (
        <Container>
            <Error404>Page Not Found :(</Error404>
            <PrimaryButton2 title="Go Back" onClick={() => window.location.replace('/')} />
        </Container>
    )
}