const storedApiResponse = localStorage.getItem('globalSettings');
const apiResponse = storedApiResponse ? JSON.parse(storedApiResponse) : {};
const globalSettings = 
{
  "BrandLogoPrimary": "/wp-content/uploads/2023/08/primaryLogo.svg",
  "BrandLogoSecondary": "/wp-content/uploads/2023/08/secondaryLogo.svg",
  "BrandPhoneNumbers": [
      {
          "BPhoneName": "PNG Phone",
          "BPhoneNumber": "(+675) 983 5178"
      },
      {
          "BPhoneName": "PNG Phone",
          "BPhoneNumber": "(+675) 7114 4457"
      }
  ],
  "BrandEmail": "info@ramarineservices.com",
  "BrandCareerEnquiriesEmail": "hr@randmarineservices.com",
  "BrandWorkingHours": "Mon-Fri: 9 am — 6 pm",
  "BrandAddresses": [
      {
          "BAddress": "West New Britain Bali Bridge Wharf PO Box 643 Kimbe W.N.B.P."
      },
      {
          "BAddress": "East New Britain Kokopo-Rabaul Rd, Raburua PO Box 2541"
      }
  ],
  "BrandFacebook": "/",
  "BrandInstagram": "/",
  "BrandLinkedin": "/",
  "BrandTwitter": "/",
  "BrandHeaderScript": "",
  "BrandFooterScript": "",
  "HeaderButton": {
      "title": "Contact",
      "url": "/contact",
      "target": ""
  },
  "FooterTaglineTitle": "Enhance Your Life By Having A Sense Of Purpose",
  "FooterTaglineContent": "Around the world, food-borne illnesses have become increasingly common. In the United States alone, millions of people get a food-related illness each year.",
  "FooterCopyright": "All rights reserved",
  "Colors": {
      "primary": "rgb(16,31,67)",
      "primaryLight": "#274BA1",
      "secondary": "rgb(238,231,220)",
      "secondaryLight": "#F6F3ED",
      "white": "#FFFFFF",
      "red": "#EB564F"
  },
  "FontProperty": {
      "TextColorPrimary": "#101f43",
      "TextColorSecondary": "#eee7dc",
      "TextColorWhite": "#FFFFFF",
      "TextColorRed": "#FF7D78",
      "TextBold": {
          "fontFamily": "Barlow",
          "fontWeight": "700"
      },
      "TextSemiBold": {
          "fontFamily": "Barlow",
          "fontWeight": "600"
      },
      "TextMedium": {
          "fontFamily": "Barlow",
          "fontWeight": "500"
      },
      "TextRegular": {
          "fontFamily": "Barlow",
          "fontWeight": "400"
      },
      "TextLight": {
          "fontFamily": "Barlow",
          "fontWeight": "300"
      },
      "h1": {
          "fontFamily": "Barlow",
          "fontWeight": "600",
          "fontsize": "76",
          "lineHeight": "91.2"
      },
      "h2": {
          "fontFamily": "Barlow",
          "fontWeight": "600",
          "fontsize": "52",
          "lineHeight": "64"
      },
      "h3": {
          "fontFamily": "Barlow",
          "fontWeight": "600",
          "fontsize": "40",
          "lineHeight": "48"
      },
      "h4": {
          "fontFamily": "Barlow",
          "fontWeight": "600",
          "fontsize": "32",
          "lineHeight": "40"
      },
      "h5": {
          "fontFamily": "Barlow",
          "fontWeight": "500",
          "fontsize": "24",
          "lineHeight": "32"
      },
      "h6": {
          "fontFamily": "Barlow",
          "fontWeight": "500",
          "fontsize": "20",
          "lineHeight": "24"
      },
      "p": {
          "fontFamily": "Public Sans",
          "fontWeight": "600",
          "fontsize": "16",
          "lineHeight": "24",
          "color": "#1d2e50"
      },
      "label": {
          "fontFamily": "Public Sans",
          "fontWeight": "700",
          "fontsize": "12",
          "lineHeight": "18",
          "color": "rgb(238,231,220)"
      },
      "placeholder": {
          "fontFamily": "Barlow",
          "fontWeight": "400",
          "fontsize": "16",
          "lineHeight": "24",
          "color": "rgb(238,231,220)"
      }
  },
  Container: {
    md: 16,
    lg: 100,
    xl: 140,
  },
}
 


export default globalSettings;
