import { useEffect, useState } from 'react';
import globalSettings from 'src/constants/globalSettings';
import getIcon from 'src/utils/getIcon';
import { FONT_COLORS } from 'src/theme/fontColors';
import getIconAnimation from 'src/utils/getIconAnimation';
import Image from 'src/components/Image';
import {
    Heading,
    Description,
    Container,
    ContentWrapper,
    MaskWrapper,
    ImageWrapper,
    SecondaryBackground,
    LeftSideWrapper,
    RightSideWrapper
} from './styles';

const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };
interface PrimaryBannerProps {
    PBTitle: string;
    PBTitleSize: string;
    PBContentColumnOne: string;
    PBContentColumnTwo: string
    PBImage: string
    PBMask: string
    PBPrimaryBgColour: string
    PBSecondaryBgColour: string
}
export default function PrimaryBanner({
    PBTitle,
    PBTitleSize,
    PBContentColumnOne,
    PBContentColumnTwo,
    PBImage,
    PBMask,
    PBPrimaryBgColour,
    PBSecondaryBgColour
}: PrimaryBannerProps) {
    const [color, setColor] = useState("")

    useEffect(() => {
        setColor(FONT_COLORS[PBPrimaryBgColour as "#0F1F43" | "#EEE7DC" | "#274BA1" | "#F6F3ED"] || "#F6F3ED")
    }, [PBPrimaryBgColour])

    return (
        <Container className='PrimaryBanner' backgroundColor={PBPrimaryBgColour}>
            <ContentWrapper>
                <MaskWrapper
                    icontype={PBMask}
                    iconcolor={color}
                    initial={{ rotate: 0, x: 0 }}
                    animate={getIconAnimation(PBMask)}
                >
                    {getIcon(PBMask)}
                </MaskWrapper>
                <LeftSideWrapper>
                    <Heading
                        sx={[globalSettings.FontProperty.TextBold, { color}]}
                        dangerouslySetInnerHTML={{ __html: PBTitle }}
                    />
                </LeftSideWrapper>
                <RightSideWrapper>
                    {PBContentColumnOne !== "" &&
                        <Description
                            sx={{ color }}
                            dangerouslySetInnerHTML={{ __html: PBContentColumnOne }}
                        />
                    }
                    {PBContentColumnTwo !== "" &&
                        <Description
                            sx={{ color }}
                            dangerouslySetInnerHTML={{ __html: PBContentColumnTwo }}
                        />
                    }

                </RightSideWrapper>

            </ContentWrapper>
            <ImageWrapper>
                <Image
                    src={`https://${REACT_APP_CMS_HOST}${PBImage}`}
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                />
            </ImageWrapper>
            <SecondaryBackground backgroundColor={PBSecondaryBgColour} />
        </Container>
    )
}

