import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, styled, BoxProps } from '@mui/material'
import { useRecoilState } from "recoil";
import { useLocation } from 'react-router-dom';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import { navItemsProps } from 'src/components/MobileHeaderOptions/navItems';
import { atomMap } from "../../../atoms";
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phoneIcon.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as Polygon } from '../../../assets/icons/polygon.svg';
import ServiceCard from './ServiceCard';



export const ModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  top: '100%',
  zIndex: 1999,
  paddingLeft: '140px',
  paddingRight: '140px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    paddingLeft: '100px',
    paddingRight: '100px',
  },
}));

export const ModalContentWrapper = styled(Box)(({ theme }) => ({
  padding: '65px 140px',
  paddingBottom: pxToRem(30),
  paddingRight: pxToRem(66),
  gap: '45px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: globalSettings.Colors.white,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down(1400)]: {
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px',
    gap: pxToRem(40)
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: pxToRem(50),
    gap: pxToRem(30)
  },
}));
export const InfoContainer = styled(Box)(({ theme }) => ({
  paddingBottom: pxToRem(24),
  display: 'flex',
  gap: pxToRem(16),
  '& svg': {
    height: pxToRem(28),
    width: pxToRem(28),
  },
  '& svg path': {
    stroke: globalSettings.Colors.primary,
    strokeWidth: 0
  },
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
  lineHeight: pxToRem(28),
  color: globalSettings.Colors.primary,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    width: '100%',
    marginBottom: pxToRem(4)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',

  },
}));

export const InfoContent = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: pxToRem(14),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(22),
  opacity: 0.7,
  color: globalSettings.Colors.primary,
  '&:hover': {
    color: globalSettings.Colors.red
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    width: '100%',
    paddingBottom: pxToRem(10)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',

  },
}));

export const PolygonContainer = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '-4%',
  right: '44%',
  [theme.breakpoints.down('md')]: {
  },
  [theme.breakpoints.between('md', 'lg')]: {
    right: '41%',
    top: '-3%',
  },
  [theme.breakpoints.between(1200,1400)]: {
    right: '42%',
    top: '-3.2%',
  },
}));

export const ServiceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: pxToRem(40),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(20),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    gap: pxToRem(20),
  },
}));

export const RightSideContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down(1400)]: {
    flexDirection: 'row',
    gap: '50px',
    alignItems: 'initial',
    justifyContent: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'row',
    gap: '50px',
    alignItems: 'initial',
    justifyContent: 'center',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
  },
}));

interface ModalProps extends BoxProps {
  hidden: boolean;
  setState: any;
  onMouseLeave?: () => void
  onMouseOver?: () => void;
  currentPage: navItemsProps | null;
  showHeaderOptions?: boolean;
}
export default function Modal({ 
  hidden, 
  setState, 
  onMouseLeave,
  onMouseOver,
  currentPage,
  showHeaderOptions }: ModalProps) {
  const [settings] = useRecoilState(atomMap.settings)
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const containerRef = useRef<null | HTMLElement>(null)
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const location = useLocation();

  // State to store the current URL
  const [currentURL, setCurrentURL] = useState<string>(location.pathname);
  

  useEffect(() => {
    setIsHidden(hidden)
    
  }, [hidden])

  

  useEffect(()=>{
    if(currentPage?.child_items){
        const activeCategory = currentPage.child_items[0].title;
        if(currentCategory !== activeCategory){
            setCurrentCategory(activeCategory)
        }
    }
    // eslint-disable-next-line
}, [currentPage])


  const _navigate = (url: string) => {
    window.open(url, '_blank')
  };

      
  return (
    
    <ModalContainer
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      ref={containerRef}
      sx={{
        display: showHeaderOptions && currentPage?.child_items ? 'block' : 'none'
      }}
      
    >
      <PolygonContainer>
        <Polygon />
      </PolygonContainer>
      
      <ModalContentWrapper>
        <ServiceContainer >
          {currentPage?.child_items?.map((item: any, index: number) =>
            <ServiceCard hideCard={onMouseLeave} key={index} id={item.object_id} width={240} />
          )}
        </ServiceContainer>
        <Box sx={{

          opacity: 0.15,
          border: '1px solid #101F43'
        }} />

        <RightSideContainer>
          <InfoContainer>
            <PhoneIcon />
            <Box>
              <InfoTitle sx={[globalSettings.FontProperty.TextSemiBold]}>Call us</InfoTitle>
              {settings?.BrandPhoneNumbers.map((item: any, index: number) =>
                <InfoContent onClick={() => _navigate(`tel:${item.BPhoneNumber}`)} key={index}>{item.BPhoneName} {item.BPhoneNumber}</InfoContent>
              )}
            </Box>
          </InfoContainer>

          <InfoContainer>
            <MailIcon />
            <Box>
              <InfoTitle sx={[globalSettings.FontProperty.TextSemiBold]}>Talk to us</InfoTitle>
              <InfoContent onClick={() => _navigate(`mailto:${settings?.BrandEmail}`)}>{settings?.BrandEmail}</InfoContent>
            </Box>
          </InfoContainer>
        </RightSideContainer>
      </ModalContentWrapper>
    </ModalContainer>
  )
}
