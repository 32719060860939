import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.primary,
  
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.between('md','xl')]: {
    marginBottom: '-1px'
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: pxToRem(globalSettings.Container.xl),
  gap: pxToRem(122),
  alignItems: 'center',
  [theme.breakpoints.down('xl')]: {
    padding: `${pxToRem(40)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(40),
    padding: `${pxToRem(40)} ${pxToRem(globalSettings.Container.md)}`,
    paddingBottom: pxToRem(64)
  },
  [theme.breakpoints.between('md', 'xl')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: pxToRem(42),
  [theme.breakpoints.down('md')]: {
    marginTop: pxToRem(40),
    display: 'flex',
    justifyContent: 'center',
  },
}));


export const MaskWrapper = styled(motion(Box))(({ theme }) => ({
  position: 'absolute',
  top: '-10%',
  left: '57px',
  height: pxToRem(154),
  width: pxToRem(182),
  '& svg': {
    height: '100%',
    width: '100%',
  },
  '& svg path': {
    stroke: globalSettings.Colors.red,
    fill: globalSettings.Colors.red
  },
  [theme.breakpoints.down('md')]: {
  
  },
  [theme.breakpoints.between('md', 'lg')]: {},
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(48),
  lineHeight: pxToRem(64),
  color: globalSettings.Colors.secondary,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
    textAlign: 'end'
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(24),
  color: globalSettings.Colors.secondary,
  marginBottom: pxToRem(16),
  textAlign: 'end',
  opacity: 0.7,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'center',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(18),
  fontWeight: 700,
  textTransform: 'uppercase',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  color: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const SubTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: pxToRem(24),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'center',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(40),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));