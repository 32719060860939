import MultiImageContentBlock from 'src/components/MultiImageContentBlock';
import TextBlock from 'src/components/TextBlock';
import PrimaryBanner from 'src/components/PrimaryBanner';
import SecondaryBanner from 'src/components/SecondaryBanner';
import ImageContentBlock from 'src/components/ImageContentBlock';
import LocationCarousel from 'src/components/LocationCarousel';
import DataBlock from 'src/components/DataBlock';
import DataBanner from 'src/components/DataBanner';
import ServiceList from 'src/components/ServiceList';
import ServiceTiles from 'src/components/ServiceTiles';
import CTABanner from 'src/components/CTABanner';
import ContactUs from 'src/components/ContactUs';
import CapabilityTiles from 'src/components/CapabilityTiles';
import ImageBanner from 'src/components/ImageBanner';

interface ComponentMap {
    [componentName: string]: React.FC<any>;
}

const components: ComponentMap = {
    CTABanner,
    CapabilityTiles,
    DataBanner,
    DataBlock,
    MultiImageContentBlock,
    ImageContentBlock,
    TextBlock,
    PrimaryBanner,
    SecondaryBanner,
    ServiceList,
    LocationCarousel,
    ServiceTiles,
    ContactUs,
    ImageBanner
  };

  export default components;