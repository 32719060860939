import { atom } from 'recoil';
import apiConfig from './apiConfig';

type AtomMap = Record<string, any>;

const atomMap: AtomMap = {};

for(let i = 0; i < apiConfig.length; i += 1){
    const api = apiConfig[i]
    atomMap[api.key] = atom({
      key: api.key,
      default: null
    });
}

export { atomMap };