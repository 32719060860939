import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import getIconAnimation from 'src/utils/getIconAnimation';
import getIcon from 'src/utils/getIcon';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import MainButton from '../Button';
import ContactForm from '../ContactForm';
import Card from './components/Card';
import Info from './components/Info';
import { atomMap } from '../../atoms';
import {
    Heading,
    Container,
    ContentWrapper,
    LeftContainer,
    RightContainer,
    FormWrapper,
    MaskWrapper
} from './styles';



interface DetailsProps {
    BPhoneName?: string;
    BAddress?: string
}

interface AddressProps {
    BAddress: string
}

interface PhoneProps {
    BPhoneName: string
    BPhoneNumber: string
}


interface InfoProps {
    careerEmail: string;
    address: AddressProps[]
    phone: PhoneProps[]
    workingHours: string;
    email: string;

}

interface ContactUsProps {
    CUForm: number;
    CUFormTitle: string;
    CUTitle: string;

}
export default function ContactUs({ CUForm, CUFormTitle, CUTitle }: ContactUsProps) {
    const isMobile = useResponsive('down', 'md')
    const [settings] = useRecoilState(atomMap.settings)
    const [data, setData] = useState<InfoProps | null>(null)

    useEffect(() => {
        if (settings) {
            const infoData = {
                careerEmail: settings?.BrandCareerEnquiriesEmail,
                address: settings?.BrandAddresses,
                phone: settings?.BrandPhoneNumbers,
                workingHours: settings?.BrandWorkingHours,
                email: settings?.BrandEmail
            }

            setData(infoData)
        }

    }, [settings])
    return (

        <Container className='ContactUs'>
            <MaskWrapper
                    icontype="Helm"
                    iconcolor={globalSettings.Colors.primary}
                    initial={{ rotate: 0, x: 0 }}
                    animate={getIconAnimation("Helm")}
                >
                    {getIcon("Helm")}
                </MaskWrapper>
            <ContentWrapper>

                <Heading
                    sx={[globalSettings.FontProperty.TextBold]}
                    dangerouslySetInnerHTML={{ __html: CUTitle }}
                />
                <FormWrapper>
                    <LeftContainer>


                        {
                            data && <Info {...data} />
                        }
                    </LeftContainer>
                    <RightContainer>
                        <ContactForm id={CUForm} title={CUFormTitle}/>
                    </RightContainer>
                </FormWrapper>

            </ContentWrapper>
        </Container>

    )
}
