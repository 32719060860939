import { styled, Button } from "@mui/material";
import { Link } from "react-router-dom";

const defaults = {
    borderRadius: {
        rounded: '100vw 100vw',
        rectangle: '24px 24px'
    },
    fontSize: '0.83vw',
    padding: '1.45vw 3.5vw'
}

type ButtonProps = {
    bgcolor: string;
    bordercolor?: string;
    textcolor: string;
    fontSize?: string;
    padding?: string;
    rounded?: 0 | 1;
    iconcolor?: string;
    height?: string;
    width?: string;
    activecolor?: string;
    opacity?: string
}

const style = (({bgcolor, opacity,bordercolor, textcolor, fontSize, padding, rounded, iconcolor, height, width, activecolor} : ButtonProps)=>`
    all: unset;
    display: block;
    border-radius: ${rounded ? defaults.borderRadius.rounded : defaults.borderRadius.rectangle};
    background-color: ${bgcolor};
    color: ${textcolor} !important;
    ${`border: 1px solid ${bordercolor};` || ''};
    font-size: ${fontSize || defaults.fontSize};
    padding: ${padding || defaults.padding};
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${height || 'fit-content'};
    width: ${width || "fit-content"};
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 600;
    line-height: 0;
    svg, path {
        stroke: ${iconcolor || textcolor};
        color: ${iconcolor || textcolor};
        width: 1.3vw;
        height: fit-content;
    }
    &:hover {
        background-color: ${bgcolor}CC;
    }
    & .MuiTouchRipple-root {
        overflow: hidden;
    }
    &:active {
        background-color: ${activecolor || '#0B0F66'};
    }
`)

export const ButtonStyle = styled(Button)<ButtonProps>(({bgcolor, opacity,bordercolor, textcolor, fontSize, padding, rounded = 1, iconcolor, height, width, activecolor}) => style({bgcolor, opacity,bordercolor, textcolor, fontSize, padding, rounded, iconcolor, height, width, activecolor}))

export const ButtonLinkStyle = styled(Link)<ButtonProps>(({bgcolor, opacity,bordercolor, textcolor, fontSize, padding, rounded = 1, iconcolor, height, width, activecolor}) => style({bgcolor, opacity,bordercolor, textcolor, fontSize, padding, rounded, iconcolor, height, width, activecolor}))