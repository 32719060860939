const getIconAnimation = (iconType: string) => {
  switch (iconType) {
    case 'Helm':
      return {
        rotate: [0, 360], // Spin 360 degrees
        transition: { repeat: Infinity, duration: 45, ease: 'linear' },
      };
    case 'Anchor':
      return {
        y: [0, -50, 0],
        transition: { repeat: Infinity, duration: 5, yoyo: Infinity, ease: 'linear' },
      };
    case 'Sea Mew':
      return {
        y: [0, -20, 0], // Move the icon up and down to simulate flying
        rotate: [0, 10, 0], // Slight rotation to mimic flying motion
        transition: { repeat: Infinity, duration: 10, ease: 'easeInOut', yoyo: Infinity },
      };
    // Add more cases for other SVG types here
    default:
      return {};
  }
};

export default getIconAnimation;
