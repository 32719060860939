import { Box, Typography, styled, Divider } from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import CountUp from 'src/components/CountUp';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'

const Title = styled(Typography)(({ theme }) => ({
    color: globalSettings.Colors.secondary,
    fontFamily: 'Barlow',
    '& div': {
        fontSize: pxToRem(52),
        lineHeight: pxToRem(64),
        fontFamily: 'Barlow',
        fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
        '& div': {
            fontSize: pxToRem(32),
            lineHeight: pxToRem(42),
        }
        
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',

    },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: globalSettings.Colors.secondary,
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    fontWeight: 700,
    fontFamily: 'Public Sans',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100px',
    },
    [theme.breakpoints.between('md', 'lg')]: {

    },
}));

const Description = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    color: globalSettings.Colors.secondary,
    opacity: 0.7,
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    marginLeft: pxToRem(40),
    fontWeight: 400,
    fontFamily: 'Public Sans',
    [theme.breakpoints.down('md')]: {
        maxWidth: '50%'
    },
    [theme.breakpoints.between('md', 'lg')]: {

    },
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    },
  }));

  const RightContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    minWidth: '160px',
    marginRight: pxToRem(24),
    [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '30%',
    },
    [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
        minWidth: '30%',
    },
  }));

  const NumberContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& svg path': {
        stroke: globalSettings.Colors.white,
        fill: globalSettings.Colors.white
      },
    [theme.breakpoints.down('md')]: {},
  }));

interface CardProps{
    DBLItemNumber: number;
    DBLItem: string
    DBLItemDesc: string
}
export default function Card({DBLItemNumber, DBLItem, DBLItemDesc}: CardProps) {
  return (
    <Container>
        <RightContainer>
            <NumberContainer>
                <Title>
                    <CountUp from={0} to={DBLItemNumber} />
                </Title>
                <Plus/>
            </NumberContainer>
            <SubTitle>{DBLItem}</SubTitle>
        </RightContainer>
        <Divider orientation="vertical" flexItem/>
        <Description dangerouslySetInnerHTML={{__html: DBLItemDesc}}/>
    </Container>
  )
}
