import { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import Modal from "./components/Modal";
import { atomMap } from "../../atoms";
import MainButton from "../Button";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron_down_bold.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron_up_bold.svg";
import Image from "../Image";
import {
  Container,
  NavLogo,
  ContentWrapper,
  CenterContainer,
  RightContainer,
  LeftContainer,
  NavMenu,
  ChevronButton
} from "./styles";
import useResponsive from "../../hooks/useResponsive";
import MobileHeader from "../MobileHeader";
import { navItemsProps } from "../MobileHeaderOptions/navItems";

const {
  REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };


type activeCategoryProps = {
  item: navItemsProps;
  event: React.MouseEvent<HTMLSpanElement, MouseEvent>;
  index?: number
};
export default function Header() {
  const [activePage, setActivePage] = useState<number>(0)
  const [header] = useRecoilState(atomMap.header)
  const [settings] = useRecoilState(atomMap.settings)
  const isMobile = useResponsive('down', 'md');
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [hidden, setHidden] = useState<boolean>(true)
  const hideHeaderTimeout = useRef<NodeJS.Timeout | null>(null)
  const [offsetLeft, setOffsetLeft] = useState<number>(0)
  const [showHeaderOptions, setShowHeaderOptions] = useState<boolean>(false) 
  const [currentPage, setCurrentPage] = useState<navItemsProps | null>(header ? header[0] : null);


  const setActiveCategory = ({ item, event,index }: activeCategoryProps) => {
    showHeader();
    setCurrentPage(item);
    const target = event.target as HTMLSpanElement;
    setOffsetLeft(target.offsetLeft);
    if(index){
      if (activePage === index) {
        setActivePage(0)
      } else {
        setActivePage(index)
      }
    }
   
  };

  const hideHeader = (index:number) => {
    if(index){
      if (activePage === index) {
        setActivePage(0)
      } else {
        setActivePage(index)
      }
    }
    if(hideHeaderTimeout.current){
        clearTimeout(hideHeaderTimeout.current)
    }
    hideHeaderTimeout.current = setTimeout(()=>{
        setShowHeaderOptions(false)
    }, 300)
  }

  const showHeader = () => {
    if(hideHeaderTimeout.current){
        clearTimeout(hideHeaderTimeout.current)
    }
    setShowHeaderOptions(true)
  }


  if (header && settings) {

    const ButtonGroup = {
      ButtonVariant: "secondary",
      Button: {
        title: settings?.HeaderButton.title,
        url: settings?.HeaderButton.url || "",
        target: settings?.HeaderButton.target
      }
    }


    if (isMobile) {
      return <MobileHeader />
    }
    return (
        <Container ref={headerRef}>
          <ContentWrapper>

            <LeftContainer>
              <NavLogo to="/">
                <Image
                  src={`https://${REACT_APP_CMS_HOST}${settings.BrandLogoPrimary}`}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </NavLogo>
            </LeftContainer>

            <CenterContainer>
              {header.map((item: navItemsProps, index: number) =>
                <Box key={index}>
                  <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', }}>
                    <NavMenu 
                    to={item.url} 
                    onClick={()=>setHidden(true)}
                    onMouseLeave={()=>hideHeader(index)}
                    onMouseOver={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => setActiveCategory({ item, event,index })}
                    >
                      {item.title}
                    </NavMenu>

                    {item.child_items && (
                      index === activePage ?
                        <ChevronButton
                          sx={{cursor: 'pointer'}}
                          onClick={()=>hideHeader(index)}
                        >
                          <ChevronUp />
                          
                        </ChevronButton>
                        :
                        <ChevronButton
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => setActiveCategory({ item, event,index })}
                        >
                          <ChevronDown />
                        </ChevronButton>
                    )}
                  </Box>

                </Box>
              )}
            </CenterContainer>

            <RightContainer>
              <MainButton ButtonGroup={ButtonGroup} />
            </RightContainer>


          </ContentWrapper>
          <Modal 
          setState={setHidden} 
          hidden={hidden} 
          onMouseLeave={()=>hideHeader(-1)}
          onMouseOver={showHeader} 
          currentPage={currentPage} 
          showHeaderOptions={showHeaderOptions} 
          />
        </Container>
    );



  }
  return null
}