import React, { useEffect, useState } from 'react';
import { Button as MButton, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import globalSettings from 'src/constants/globalSettings';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevronRight.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrow.svg';


interface MainButtonProps extends ButtonProps {
  ButtonGroup: ButtonGroupProps,
  minWidth?: string
  onClick?: () => void
}

const commonStyles = {
  maxHeight: '48px',
  padding: '11px',
  borderRadius: 0,
  textTransform: 'none',
  cursor: 'pointer',
  transition: "0.2s",
};




const MainButton: React.FC<MainButtonProps> = ({ ButtonGroup,minWidth,onClick }) => {

  const navigate = useNavigate();

  const _navigate = (url: string) => {
    navigate(url);
  };

  const buttonStyles = {
    primary: {
      minWidth: minWidth || 0,
      color: globalSettings.Colors.red,
      '&:hover': {
        backgroundColor: "#E5E5E5",
        '& .MuiButton-endIcon': {
          transition: "padding-left 0.4s ease-in-out 0.2s",
          paddingLeft: '16px'
        },
      },
      '&:active': {
       color: "#BD3730"
      },
    },
    primary2: {
      minWidth: minWidth || 0,
      color: globalSettings.Colors.red,
      '&:hover': {
        backgroundColor: "transparent",
      },
    },
    secondary: {
      minWidth: minWidth || 0,
      backgroundColor: globalSettings.Colors.red,
      color: globalSettings.Colors.white,
      padding: '8px 16px',
      maxHeight: '40px',
      fontSize: '20px',
      '&:hover': {
       
        backgroundColor: globalSettings.Colors.red,
        '& .MuiButton-endIcon': {
          transition: "padding-left 0.4s ease-in-out 0.2s",
          paddingLeft: '16px'
        },
      },
      '&:active': {
        backgroundColor: '#BD3730',

      },
    },
  };
  function renderEndIcon(type: string){
   switch(type){
    case "primary":
      return <ChevronRight/>
      break;
    case "secondary":
      return <RightArrow/>
      break;
    default:
      return null
   }
  }

  return (
    <MButton
      disableRipple
      onClick={onClick || (() => _navigate(ButtonGroup.Button.url))}
      sx={{
        ...commonStyles,
        ...buttonStyles[ButtonGroup.ButtonVariant as 'primary' | 'primary2' | 'secondary'],
        '& svg':{
          width: '24px',
          height: '24px'
        },
        '& span, svg path': {
          fill: ButtonGroup.ButtonVariant === "primary" ? globalSettings.Colors.red : 'white',
        },
      }}
      endIcon={renderEndIcon(ButtonGroup.ButtonVariant) }
    >
      {ButtonGroup.Button.title}
    </MButton>
  );
};

export default MainButton;
