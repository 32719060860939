import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import { fetchDataFromAPI, url_format } from 'src/atoms/apiConfig';
import MainButton from 'src/components/Button';
import Image from 'src/components/Image';
import { useState, useEffect } from 'react';


const SERVICE_ENDPOINT = "services/?id="

const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

const Container = styled(Box)(({ theme }) => ({
    minHeight: '100%',
    position: 'relative',
    zIndex: 99,
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        maxWidth: '100%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        padding: '8px'
    },

}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    gap: pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
    },
}));


const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(28),
    wordBreak: 'break-word',
    minHeight: '58px',
    color: globalSettings.Colors.primary,
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(18),
        width: '100%',
        marginBottom: pxToRem(20),
        minHeight: 'initial',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        fontSize: pxToRem(16),
        width: '100%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
       
    },
}));

const Content = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(18),
    fontFamily: 'Public Sans',
    lineHeight: pxToRem(22),
    fontWeight: 400,
    color: globalSettings.Colors.primary,
    '& p,h5,h6': {
        display: 'inline',
        margin: 0,
        padding: 0,
        fontWeight: 400,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(18),
        width: '100%',
        marginBottom: pxToRem(20)
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
    },
}));


const ImageBox = styled(Box)(({ theme }) => ({

    width: '100%',
    height: pxToRem(153),
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '100%'
    },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
    },
    [theme.breakpoints.between('lg', 'xl')]: {
    },
}));

interface DataProps {
    link: string;
    title: {
        rendered: string
    };
    acf: {
        ServiceImage: string;
    }
    content: {
        rendered: string
    };
}
interface CardProps {
    id: number;
    width?: number;
    hideCard?: () => void 
}

const LIMIT_WORD = 8

export default function ServiceCard({ id, width, hideCard }: CardProps) {
    const [data, setData] = useState<DataProps | null>(null);

    const ButtonGroup = {
        ButtonVariant: "primary",
        Button: {
            title: "Learn more",
            url: data?.link || "",
            target: ""
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${url_format}${SERVICE_ENDPOINT}${id}`
            const { data: res } = await fetchDataFromAPI(endpoint);
            setData(res[0])
        }

        fetchData();

    }, [id])

    const navigate = useNavigate();

    const _navigate = (url: string) => {
        navigate(url);
        if (hideCard) {
            hideCard();
          }
    };

    const limitTextToWords = (text: string) => {
        const words = text.split(' ');
        const slicedText = words.slice(0, LIMIT_WORD).join(' ');

        return `${slicedText}${words.length > LIMIT_WORD ? '...' : ''}`;
    };
    return (
        <Container 
            onClick={() => _navigate(data?.link || "#")} 
            className="ServiceCard" 
            sx={{ maxWidth: pxToRem(width || 446) || pxToRem(446) }}
        >
            <ContentWrapper>
                <ImageBox>
                    <Image
                        src={`https://${REACT_APP_CMS_HOST}${data?.acf.ServiceImage}`}
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </ImageBox>


                <Title 
                    sx={[globalSettings.FontProperty.TextSemiBold]}
                    dangerouslySetInnerHTML={{ __html: data?.title.rendered || "" }}
                />
                <Content 
                dangerouslySetInnerHTML={{ __html: limitTextToWords(data?.content.rendered || "") }} 
                />
                <ButtonContainer>
                    <MainButton 
                        className='btn' 
                        minWidth='10px' 
                        ButtonGroup={ButtonGroup} 
                        onClick={() => _navigate(data?.link || '#')} 
                    />
                </ButtonContainer>
            </ContentWrapper>
        </Container>
    )
}
