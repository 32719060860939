import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { FONT_COLORS } from 'src/theme/fontColors';
import Image from 'src/components/Image';
import { Heading, Description, Container, ContentWrapper, ImageWrapper,LeftSideWrapper,Rectangle, RightSideWrapper } from './styles';
import RectangleMask from '../RectangleMask';

const {
  REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };



interface ImageContentBlockProps {
    ICBBgColour: string;
    ICBContent: string;
    ICBImage: string;
    ICBMaskPosition: string[];
    ICBTitle: string
}
export default function ImageContentBlock({ ICBBgColour, ICBContent, ICBImage,ICBMaskPosition, ICBTitle }: ImageContentBlockProps) {
    const isMobile = useResponsive('down', 'md')
    const [color, setColor] = useState("")


    useEffect(() => {
        setColor(FONT_COLORS[ICBBgColour as "#0F1F43" | "#EEE7DC" | "#274BA1" | "#F6F3ED"] || "#F6F3ED")
    }, [ICBBgColour])
    return (
        <Container className='ImageContentBlock' backgroundColor={ICBBgColour}>
           {ICBMaskPosition.map((item,index)=>
            <Rectangle key={index} positiontype={item} backgroundColor={ICBBgColour}/>
           )}
            <ContentWrapper>
              <LeftSideWrapper>
                <Heading
                    dangerouslySetInnerHTML={{__html: ICBTitle}}
                    sx={[globalSettings.FontProperty.TextBold,{
                       color
                    }]}
                />
                <ImageWrapper>
                  <Image
                        src={`https://${REACT_APP_CMS_HOST}${ICBImage}`}
                        sx={{
                          height: "100%",
                          width: '100%'
                        }}
                    />
                </ImageWrapper>
              </LeftSideWrapper>

              <RightSideWrapper>
                <Description
                     dangerouslySetInnerHTML={{__html: ICBContent}}
                     sx={{color}}
                />
              </RightSideWrapper>
            </ContentWrapper>
        </Container>
    )
}
