const { 
    REACT_APP_API_HOST,
    REACT_APP_API_PATH,
    REACT_APP_API_VERSION
} = process.env as { [key: string]: string };

export const url_format = `https://${REACT_APP_API_HOST}${REACT_APP_API_PATH.replace('%version%', `v${REACT_APP_API_VERSION}`)}`

const apiConfig = [
    {
        url: `${url_format}settings`,
        key: 'settings'
    },
    {
        url: `${url_format}menus/footer`,
        key: 'footer'
    },
    {
        url: `${url_format}menus/main`,
        key: 'header'
    }
];
  
export default apiConfig;

export const fetchDataFromAPI = async (url: string) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error(`Error fetching data: ${response.status} ${response.statusText}`);
            return null
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null
    }
};  

export const checkPageStatus = async (url: string) => {
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return null
    }
};  