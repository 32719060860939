import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import Image from 'src/components/Image';

const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

interface ImageProps {
    DBImage: string;
}

interface ImageGridProps {
    images: ImageProps[];
}

export default function ImageGrid({ images }: ImageGridProps) {
    const [data, setData] = useState<ImageProps[] | undefined>(undefined);
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('down', 'md');

    useEffect(() => {
        if (isMobile) {
            setData([images[0]]);
        } else if (isTablet) {
            setData(images.slice(0, 2));
        } else {
            setData(images);
        }
    }, [images, isMobile, isTablet]);

    const renderImage = (image: string, index: number, size: number) => (
        <Grid key={index} item lg={size} xs={12}>
            <Image
                src={`https://${REACT_APP_CMS_HOST}${image}`}
                sx={{
                    width: '100%',
                    height: isMobile ? '258px' : '390px',
                }}
            />
        </Grid>
    );

    const getImageSizes = (numImages?: number): number[] => {
        switch (numImages) {
            case 1:
                return [12];
            case 2:
                return [6, 6];
            case 3:
                return [6, 3, 3];
            case 4:
                return [6, 2, 2, 2];
            default:
                return [];
        }
    };

    const imageSizes = getImageSizes(data?.length);

    return (
        <Grid container spacing={3} sx={{ display: 'flex', flexWrap: 'nowrap',position: 'relative',zIndex: 3 }}>
            {data?.map((image, index) => renderImage(image.DBImage, index, imageSizes[index]))}
        </Grid>
    );
}
