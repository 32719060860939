import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.secondaryLight,
  position: 'relative',
  [theme.breakpoints.down('md')]: {},
}));
export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${pxToRem(216)} ${pxToRem(globalSettings.Container.lg)} ${pxToRem(50)}`,
  [theme.breakpoints.down('md')]: {
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.md)} ${pxToRem(50)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${pxToRem(98)} ${pxToRem(globalSettings.Container.md)} ${pxToRem(50)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {},
}));
export const TopSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: pxToRem(51),
  paddingBottom: pxToRem(80),
  position: 'relative',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(24),
    paddingBottom: pxToRem(40),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(24),
    flexDirection: 'column',
  },
}));
export const MaskWrapper = styled(motion(Box))(({ theme }) => ({
  position: 'absolute',
  right: 0,
  zIndex: 1,
  top: '104px',
  opacity: 0.03,
  height: pxToRem(977),
  width: pxToRem(977),
  '& svg': {
    height: '100%',
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    height: pxToRem(438),
    width: pxToRem(452),
    right: '-15%',
    top: '70px'
  },
  [theme.breakpoints.between('md', 'lg')]: {},
}));
export const TopSectionRightWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  justifyContent: 'space-between',
  alignSelf: 'flex-end',
  gap: pxToRem(41),
  paddingTop: pxToRem(92),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(24),
    width: '100%',
    paddingTop: 0,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(24),
    flexDirection: 'column',
    width: '100%',
    paddingTop: 0,
  },
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: pxToRem(42),
  [theme.breakpoints.down('md')]: {
    marginTop: pxToRem(40),
    display: 'flex',
    justifyContent: 'center',
  },
}));
type HeadingProps = {
  size: string;
};
export const Heading = styled(Typography)<HeadingProps>(({ theme, size }) => ({
  fontSize: size === 'H1' ? '6rem' : '3.25rem',
  lineHeight: pxToRem(64),
  color: globalSettings.Colors.primary,
  width: '50%',
  '& p,h6': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    textAlign: 'center',
    lineHeight: pxToRem(42),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
export const Description = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(24),
  color: globalSettings.Colors.primary,
  opacity: 0.7,
  width: '100%',
  '& p': {
    margin: 0,
    padding: 0,
    opacity: 0.7,
    fontSize: pxToRem(16),
    fontWeight: 400,
    ineHeight: pxToRem(24),
  },
  '& h5': {
    fontSize: pxToRem(24),
    fontWeight: 400,
    lineHeight: '120%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: pxToRem(16),
    '& p': {
      textAlign: 'center',
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
