import { Box, Grid } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import MainButton from '../Button';
import Card from './components/Card';
import { ReactComponent as SubtitleIcon } from '../../assets/icons/capability_subtitle_icon.svg';
import {
    Heading,
    Content,
    Container,
    ContentWrapper,
    ButtonContainer,
    SubTitle,
    SubTitleContainer,
    LeftContainer,
    RightContainer,
    Rectangle,
    TopRectangleWrapper,
    BottomRectangleWrapper
} from './styles';


interface CTItemsProps {
    CTItemIcon: string;
    CTItemTitle: string;
    CTItemDescription: string
}


interface CTABannerProps {
    CTSubtitle: string;
    CTTitle: string;
    CTContent: string;
    ButtonGroup: ButtonGroupProps
    CTItems: CTItemsProps[]
}
export default function CapabilityTiles({ CTSubtitle, CTTitle, CTContent, ButtonGroup, CTItems }: CTABannerProps) {
    const isMobile = useResponsive('between', 'md','lg')
    return (
        <Box className='CapabilityTiles' position='relative'>
            <TopRectangleWrapper >
                <Rectangle className='topRectangle' />
            </TopRectangleWrapper>
            <Container >
                <ContentWrapper>
                    <LeftContainer>
                        <SubTitleContainer>
                            <SubtitleIcon />
                            <SubTitle>{CTSubtitle}</SubTitle>
                        </SubTitleContainer>
                        <Heading
                            sx={[globalSettings.FontProperty.TextBold]}
                            dangerouslySetInnerHTML={{ __html: CTTitle }}
                        />
                        <Content
                            dangerouslySetInnerHTML={{ __html: CTContent }}
                        />
                        <ButtonContainer>
                            <MainButton ButtonGroup={ButtonGroup} />
                        </ButtonContainer>
                    </LeftContainer>


                    <RightContainer>
                        <Grid container spacing={4}>
                            {
                                CTItems.map((data,index) =>
                                    <Grid 
                                    key={index} 
                                    item 
                                    sm={6}
                                    xs={12} 
                                    md={4} 
                                    lg={6} 
                                    xl={4}
                                    >
                                        <Card {...data} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </RightContainer>
                </ContentWrapper>
            </Container>
            <BottomRectangleWrapper>
                <Rectangle  className='bottomRectangle' />
            </BottomRectangleWrapper>
        </Box>
    )
}
