import { Container } from './styles';

interface ImageBannerProps{
    IBImage: string;
}
export default function ImageBanner({IBImage}: ImageBannerProps) {
  return (
   <Container background_image={IBImage}/>
  )
}
