import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import pxToRem from "src/utils/functions/pxToRem";
import globalSettings from "../../constants/globalSettings";



export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: globalSettings.Colors.secondaryLight,
    position: 'fixed',
    width: '100vw',
    top: 0,
    zIndex:1000,
    [theme.breakpoints.down('md')]: {},
  }));


  export const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${pxToRem(28)} ${pxToRem(globalSettings.Container.xl)}`,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: pxToRem(40),
      padding: `${pxToRem(40)} ${pxToRem(globalSettings.Container.md)}`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gap: pxToRem(10),
      padding: `${pxToRem(28)} ${pxToRem(globalSettings.Container.lg)}`,
    },
  }));

  export const LeftContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(24),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }));
  

  export const ChevronButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(24),
    '& svg': {
      height: '20px',
      width: '20px',
      marginLeft: '4px'
    },
    '& svg path': {
      // stroke: globalSettings.Colors.red,
      fill: globalSettings.Colors.red
    },
    '&:hover':{
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }));

  export const RightContainer = styled(Box)(({ theme }) => ({
    minWidth: '150px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }));

  export const CenterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    gap: pxToRem(60),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }));


  export const NavLogo = styled(Link)(({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
    width: 'auto',
    height: pxToRem(60),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }));

  export const NavMenu = styled(Link)(({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
    fontSize: pxToRem(20),
    fontFamily: 'Public Sans',
    fontWeight: 500,
    lineHeight: pxToRem(22),
    display: 'flex',
    alignItems: 'center',
    color: globalSettings.Colors.primary,
    '&:hover': {
        color: globalSettings.Colors.red,
    },
   
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.between('md','lg')]: {
      textAlign: 'center'
    },
  }));



