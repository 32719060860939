import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import MainButton from 'src/components/Button';
import { FONT_COLORS } from 'src/theme/fontColors';
import globalSettings from 'src/constants/globalSettings_backup';
import Image from 'src/components/Image';
import RectangleMask from 'src/components/RectangleMask';
import { fetchDataFromAPI, url_format } from 'src/atoms/apiConfig';
import { ReactComponent as Boat } from '../../../assets/icons/boat.svg';
import {
    Heading,
    Description,
    Container,
    ContentWrapper,
    ImageWrapper,
    LeftSideWrapper,
    RightSideWrapper,
    SubTitleContainer,
    SubTitle
} from '../styles'


const SERVICE_ENDPOINT = "services/?id="
const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

interface CardProps {
    SLServiceBgColour: string;
    SLService: number;
    SLServiceMaskPosition: string[]
}

interface DataProps {
    link: string;
    title: { rendered: string };
    acf: { ServiceImage: string };
    content: { rendered: string };
}
export default function Card({ SLService, SLServiceBgColour, SLServiceMaskPosition }: CardProps) {
    const [color, setColor] = useState<string>("")
    const [data, setData] = useState<DataProps | null>(null);
    const ButtonGroup = {
        ButtonVariant: "primary",
        Button: {
            title: "Learn more",
            url: data?.link || "",
            target: ""
        }
    }

    useEffect(() => {
        setColor(FONT_COLORS[SLServiceBgColour as "#0F1F43" | "#EEE7DC" | "#274BA1" | "#F6F3ED"] || "#F6F3ED")
    }, [SLServiceBgColour])


    useEffect(() => {
        const fetchItems = async () => {
            const endpoint = `${url_format}${SERVICE_ENDPOINT}${SLService}`
            const { data: res } = await fetchDataFromAPI(endpoint);
            setData(res[0])
        }

        fetchItems();
    }, [SLService])

    return (
        <Container className='ServiceList' backgroundColor={SLServiceBgColour}>
            {SLServiceMaskPosition.map((item, index) =>
                <RectangleMask key={index} backgroundColor={SLServiceBgColour} positiontype={item} />
            )}
            <ContentWrapper>
                <LeftSideWrapper>
                    <SubTitleContainer>
                        <Boat />
                        <SubTitle>Our Services</SubTitle>
                    </SubTitleContainer>
                    <Heading
                        dangerouslySetInnerHTML={{ __html: data?.title.rendered || "" }}
                        sx={[globalSettings.FontProperty.TextBold, {color}]}
                    />
                    <ImageWrapper>
                        <Image
                            src={`https://${REACT_APP_CMS_HOST}${data?.acf.ServiceImage}`}
                            sx={{
                                height: "100%",
                                width: '100%'
                            }}
                        />
                    </ImageWrapper>
                </LeftSideWrapper>
                <RightSideWrapper>
                    <Description
                        dangerouslySetInnerHTML={{ __html: data?.content.rendered || "" }}
                        sx={{ color }}
                    />
                        <MainButton ButtonGroup={ButtonGroup} />
                </RightSideWrapper>
            </ContentWrapper>
        </Container>
    )
}
