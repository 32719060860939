import { Box, styled } from "@mui/material";
import {ReactComponent as LogoSvg} from '../../assets/logo/r-a.svg'
import globalSettings from "../../constants/globalSettings";

export const Container = styled(Box)`
    background-color: ${globalSettings.Colors.secondaryLight};
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`


export const Logo = styled(LogoSvg)`
    height: auto;
    width: 10vw;
    pointer-events: none;
    transform: scale(1.5);
    @media (max-width: 768px) {
        width: 20vw;
    }
`