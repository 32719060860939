import { Box, styled } from "@mui/material"
import { Link } from "react-router-dom";
import pxToRem from "src/utils/functions/pxToRem";
import globalSettings from "../../constants/globalSettings"
import { ReactComponent as MenuSvg } from '../../assets/icons/menu.svg'


type MenuProps = {
    isactive?: 0 | 1;
}

export const NavLogo = styled(Link)(({ theme }) => ({
    all: 'unset',
    cursor: 'pointer',
}));

export const Menu = styled(MenuSvg)<MenuProps>(({ theme, isactive }) => ({
    backgroundColor: 'red',
    width: '20px',
    height: '20px',
    line: {
        stroke: isactive ? globalSettings.Colors.primary : globalSettings.Colors.primary,
        transition: "0.2s",
    },
    transition: "0.2s",
    ...(isactive
        ? {
              transform: "scale(1.3)",
              "& line:nth-of-type(1)": {
                  transform: "rotate(-45deg) translateY(49%)",
                  transformOrigin: "center",
              },
              "& line:nth-of-type(2), & line:nth-of-type(3)": {
                  display: "none",
              },
              "& line:nth-of-type(4)": {
                  display: "block",
                  transform: "rotate(45deg) translate(5%, -43%)",
                  transformOrigin: "center",
              },
          }
        : {}),
}));


export const Container = styled(Box)(({ theme }) => ({

    zIndex: 9999999999,
    top: 0,
    backgroundColor: globalSettings.Colors.white,
    position: 'fixed',
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'scroll',
    overflowY: 'auto',
}));

export const MenuContainer = styled(Box)(({ theme }) => ({
    display:'flex',
    padding:'8px',
    alignItems:'center',
    justifyContent:'center',
    marginRight: '7px',
    [theme.breakpoints.down('sm')]: {
      },
}));


export const TopSection = styled(Box)(({ theme }) => ({
    padding: '10px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    alignItems: 'center',
    paddingBottom: '10px',
    position: 'sticky',
    top: 0,
    width: '100vw',
    zIndex: 9,
    transition: '0.2s',
    [theme.breakpoints.down('sm')]: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },

}));


export const Navigation = styled(Box)``

export const Option = styled(Box)``