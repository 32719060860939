import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';


export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.primary,
  [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
  },
}));


export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: pxToRem(8),
  [theme.breakpoints.down('md')]: {
  },
}));

export const BlueLine = styled(Box)(({ theme }) => ({
  border: `1px solid ${globalSettings.Colors.secondary}`,
  opacity: 0.3,
  marginTop: pxToRem(70),
  [theme.breakpoints.down('md')]: {
    marginTop: pxToRem(64),
  },
}));


export const LeftContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(24),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));


export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${pxToRem(globalSettings.Container.xl)} ${pxToRem(globalSettings.Container.xl)}`,
  paddingBottom: pxToRem(33),
  [theme.breakpoints.down('md')]: {
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.md)}`,
    paddingBottom: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
    paddingBottom: pxToRem(33),
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  marginTop: pxToRem(80),
  display: 'flex',
  justifyContent: 'space-between',
  gap: pxToRem(182),
  [theme.breakpoints.down('md')]: {
    marginTop: pxToRem(24),
    flexDirection: 'column',
    gap: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
  },
}));


export const TagLineTitle = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(24),
  fontWeight: 500,
  lineHeight: '120%',
  color: globalSettings.Colors.secondary,
  width: '100%',
  fontFamily: 'Public Sans',
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(16),
    width: '100%',
    lineHeight: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const TagLineContent = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontWeight: 400,
  lineHeight: pxToRem(24),
  color: globalSettings.Colors.secondary,
  width: '100%',
  opacity: 0.7,
  fontFamily: 'Public Sans',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const Copyright = styled(Typography)(({ theme }) => ({
  marginTop: pxToRem(26),
  fontSize: pxToRem(12),
  fontWeight: 400,
  lineHeight: pxToRem(18),
  color: globalSettings.Colors.secondary,
  width: '100%',
  textAlign: 'center',
  fontFamily: 'Public Sans',
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    width: '100%',
    marginTop: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
