import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { useLocation } from 'react-router-dom';
import globalSettings from "src/constants/globalSettings";
import { atomMap } from "src/atoms";
import { Container, TopSection, NavLogo, MenuContainer } from "./styles";
import MobileHeaderOptions from "../MobileHeaderOptions";
import Image from "../Image/Image";
import { ReactComponent as CloseMenu } from '../../assets/icons/closeMenu.svg'
import { ReactComponent as HamburgerMenu } from '../../assets/icons/menu.svg'



const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };
export default function MobileHeader() {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [settings] = useRecoilState(atomMap.settings)
    const location = useLocation();


    useEffect(() => {
        setShowMenu(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    if (settings) {
        return (
            <Container minHeight={showMenu ? '100%' : 'initial'}>
                <TopSection sx={{
                    backgroundColor: showMenu ?
                        globalSettings.Colors.white :
                        globalSettings.Colors.secondaryLight
                }}>
                    <NavLogo to="/">
                        <Image
                            alt='logo'
                            src={`https://${REACT_APP_CMS_HOST}${settings.BrandLogoPrimary}`}
                            sx={{
                                width: '100%',
                                height: '48px',
                            }}
                        />
                    </NavLogo>

                    <Box>
                        {showMenu ?
                            <MenuContainer>
                                <CloseMenu onMouseDown={() => setShowMenu(!showMenu)} />
                            </MenuContainer>
                            :
                            <Box sx={{
                                display: 'flex',
                                padding: '8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <HamburgerMenu onMouseDown={() => setShowMenu(!showMenu)} />
                            </Box>

                        }
                    </Box>
                </TopSection>

                {showMenu ? <MobileHeaderOptions /> : null}

            </Container>
        )
    }
    return null
}