import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

type ContainerProps = {
  backgroundColor: string;
};

const iconStyling = (iconType: string) => {
  switch (iconType) {
    case 'Helm':
      return {
        height: pxToRem(1073),
        width: pxToRem(1073),
        left: '-10%',
        top: '-9%',
      };
    case 'Anchor':
      return {
        height: pxToRem(1280),
        width: pxToRem(883),
        right: '-5%',
        top: 0,
      };
    case 'Sea Mew':
      return {
        height: pxToRem(1280),
        width: pxToRem(883),
        right: '-5%',
        top: '-12%',
      };

    default:
      return {};
  }
};



export const Container = styled(Box)<ContainerProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  position: 'relative',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  padding: `${pxToRem(140)} ${pxToRem(globalSettings.Container.xl)}`,
  paddingBottom: pxToRem(170),
  gap: pxToRem(80),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(64),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));

type MaskWrapperProps = {
  iconcolor: string;
  icontype: string;
};
export const MaskWrapper = styled(motion(Box))<MaskWrapperProps>(
  ({ theme, iconcolor, icontype }) => ({
    ...iconStyling(icontype),
    position: 'absolute',
    backgroundColor: 'transparent',
    opacity: 0.03,
    zIndex: 1,
    '& svg': {
      height: '100%',
      width: '100%',
    },
    '& svg path': {
      stroke: iconcolor,
      fill: iconcolor,
    },
    [theme.breakpoints.down('md')]: {
      height: pxToRem(438),
      width: pxToRem(452),
      right: '-15%',
    },
    [theme.breakpoints.between('md', 'lg')]: {},
  })
);

