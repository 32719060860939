import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import Image from 'src/components/Image';

const {
  REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };


const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  zIndex: 99,
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: pxToRem(181),
  [theme.breakpoints.down('lg')]: {
    gap: pxToRem(40),
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'initial',
  },
}));

const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const RightSideWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.between('md','lg')]: {
    width: '80%',
  },
}));


const Rectangle = styled(Box)(({ theme }) => ({
  width: pxToRem(24),
  height: pxToRem(3),
  marginBottom: pxToRem(16),
  backgroundColor: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    marginBottom: pxToRem(24),
  },
}));


const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(24),
  lineHeight: '120%',
  fontFamily: 'Public Sans',
  fontWeight: 600,
  textTransform: 'capitalize',
  marginBottom: pxToRem(32),
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    lineHeight: pxToRem(28),
    fontFamily: 'Barlow',
    marginBottom: pxToRem(24)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));


const Description = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(24),
  opacity: 0.7,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

interface MICBItemsProps {
  MICBItemContent: string;
  MICBItemImage: string;
  MICBItemTitle: string;
  fontColor: string
}



export default function Card({ MICBItemContent, fontColor, MICBItemImage, MICBItemTitle }: MICBItemsProps) {
  return (
    <Container>
      <LeftSideWrapper>
        <Rectangle />
        <Heading
          dangerouslySetInnerHTML={{ __html: MICBItemTitle }}
          sx={{
            color: fontColor,
          }}

        />
        <ImageWrapper>
          <Image
            src={`https://${REACT_APP_CMS_HOST}${MICBItemImage}`}
          />
        </ImageWrapper>
      </LeftSideWrapper>
      <RightSideWrapper>
        <Description
          dangerouslySetInnerHTML={{ __html: MICBItemContent }}
          sx={{
            color: fontColor
          }}
        />
      </RightSideWrapper>
    </Container>
  )
}
