import { useRoutes } from 'react-router-dom';
import { useEffect } from 'react';

import Page from 'src/Page';

import { useRecoilCallback } from 'recoil';
import { atomMap } from '../atoms';
import apiConfig, { fetchDataFromAPI } from '../atoms/apiConfig';

export default function Router() {

  const setAtomValues = useRecoilCallback(({ set } : {set : any}) => async (responses: { key: any; data: any; error: any; }[]) => {
    responses.forEach(({ key, data, error }) => {
      if (!error) {
        set(atomMap[key], data);
      } else {
        console.log(error)
      }
    });
  }, []);

  useEffect(() => {

    const fetchDataFromAPIs = async () => {

      try {

        const promises = apiConfig.map(async (api) => {
          try {
            const responseData = await fetchDataFromAPI(api.url);
            return { key: api.key, data: responseData };
          } catch (error) {
            return { error, api }
          }
        });

        const responses = await Promise.all(promises);

        setAtomValues(responses)

      } catch (error) {
        console.log({fetchDataFromAPIs: error})
      }
    };

    fetchDataFromAPIs();

  }, [setAtomValues]);

  return useRoutes([{ path: '*', element: <Page /> }]);

}