import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.primary,
  [theme.breakpoints.down('md')]: {},
}));

export const TopRectangleWrapper = styled(Box)(({ theme }) => ({
  '& .topRectangle':{
    top: '-6%',
  },
  [theme.breakpoints.down('md')]: {
    '& .topRectangle':{
      top: '-2%',
    },
  },
  [theme.breakpoints.between('md','lg')]: {
    '& .topRectangle':{
      top: '-3%',
    },
  },
}));

export const BottomRectangleWrapper = styled(Box)(({ theme }) => ({
  '& .bottomRectangle':{
    bottom: '-6%',
    right: 0
  },
  [theme.breakpoints.down('md')]: {
    '& .bottomRectangle':{
      bottom: '-2%',
      right: 0,
      width: pxToRem(100),
      height: pxToRem(52),
    },
  },
  [theme.breakpoints.between('md','lg')]: {
    '& .bottomRectangle':{
      bottom: '-3%',
      right: 0
    },
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: pxToRem(globalSettings.Container.xl),
  gap: pxToRem(180),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(40),
    padding: `${pxToRem(80)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    gap: pxToRem(40),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));

export const Rectangle = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.primary,
  width: pxToRem(380),
  height: pxToRem(136),
  position: 'absolute',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: pxToRem(130),
    height: pxToRem(80),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: pxToRem(200),
    height: pxToRem(136),
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const SubTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(32),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    gap: pxToRem(24),
  },
  [theme.breakpoints.between('md','lg')]: {
    width: '100%',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md','lg')]: {
    width: '100%',
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(48),
  lineHeight: pxToRem(64),
  color: globalSettings.Colors.secondary,
  width: '100%',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    textAlign: 'center',
    lineHeight: pxToRem(42),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(24),
  color: globalSettings.Colors.secondary,
 
  maxWidth: '90%',
  '& h5, h6': {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    fontWeight: 500,
   
  },
  ' h6:first-of-type,h5:first-of-type': {
    marginTop:0,
    marginBottom: pxToRem(32),
  },
  '& p': {
    marginBottom: 0,
    marginTop: 0,
    opacity: 0.7,
    lineHeight: pxToRem(24),
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  '& li': {
    marginBottom: 0,
    marginTop: 0,
    opacity: 0.7,
    lineHeight: pxToRem(24),
    fontWeight: 400,
    fontSize: pxToRem(16),
  },
  [theme.breakpoints.down('md')]: {
    ' h6:first-of-type,h5:first-of-type': {
      marginTop:0,
      marginBottom: pxToRem(24),
    },
    maxWidth: '100%',
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '100%',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(18),
  fontWeight: 700,
  textTransform: 'uppercase',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  color: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
