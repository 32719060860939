import globalSettings from 'src/constants/globalSettings';
import { ButtonGroupProps } from 'src/interface/Button.interface';
import MainButton from '../Button';
import { ReactComponent as WaveIcon } from '../../assets/icons/wave-icon.svg';
import { Heading, 
    Content, 
    Container, 
    ContentWrapper, 
    SubTitleContainer,
    SubTitle,
    LeftContainer,
    RightContainer
} from './styles';
import Card from './components/Card';


interface DBLItemsProps{
    DBLItemNumber: number;
    DBLItem: string
    DBLItemDesc: string
}

interface DataBlockProps {
    DBLSubtitle: string;
    DBLTitle: string;
    DBLContent: string;
    ButtonGroup: ButtonGroupProps;
    DBLItems: DBLItemsProps[]
}
export default function DataBlock({ DBLSubtitle, DBLTitle, DBLContent,ButtonGroup,DBLItems }: DataBlockProps) {
    return (
        <Container className='DataBlock'>
           
            <ContentWrapper>
                <LeftContainer>
                    <SubTitleContainer>
                        <WaveIcon/>
                        <SubTitle>{DBLSubtitle}</SubTitle>
                    </SubTitleContainer>
                    <Heading 
                    sx={[globalSettings.FontProperty.TextBold]}
                    dangerouslySetInnerHTML={{__html:DBLTitle}}
                    />
                    <Content
                    dangerouslySetInnerHTML={{__html: DBLContent}}
                    />
                    <MainButton ButtonGroup={ButtonGroup}/>
                </LeftContainer>
                
                <RightContainer>
                    {DBLItems.map((item,index)=>
                        <Card {...item} key={index}/>
                    )}
                </RightContainer>
            </ContentWrapper>
        </Container>
    )
}
