import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { snackbarAtom } from "src/atoms/snackbar";
import { fetchDataFromAPI, url_format } from "src/atoms/apiConfig";
import globalSettings from "src/constants/globalSettings";
import Chip from "../Chip";
import Input from "../Input";
import SubmitButton from "./components/SubmitButton";
import { Container, Form, Heading, ChipHeading,MissingText, ThankYouTitle, ThankYouContent, ThankYouContainer } from "./styles";
import { InputProps } from '../../types/input'

interface ContactFormProps {
    id: number;
    title: string;
}

interface FormData {
    [key: number]: any;
}
type ContactFormDataProps = {
    button: {
        text: string;
    };
    fields: InputProps[];
    title: string;
}

interface CheckBoxChoices {
    text: string;
    value: string;
}
interface CheckboxData {
    label: string;
    id: number;
    choices: CheckBoxChoices[]
}

// ... (import statements remain the same)

export default function ContactForm({ id, title }: ContactFormProps) {
    const setSnackbar = useSetRecoilState(snackbarAtom);
    const [contactForm, setContactForm] = useState<ContactFormDataProps | null>(null);
    const [formErrors, setFormErrors] = useState<Record<number, string>>({});
    const [buttonText, setButtonText] = useState<string | undefined>(""); // Initialize with null
    const [dataForm, setDataForm] = useState<FormData>({});
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [errorLabel, setErrorLabel] = useState<string>("");
    const [checkBoxData, setCheckBoxData] = useState<CheckboxData>()
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState<number[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!contactForm) {
                const url = `${url_format}form?id=${id}`;
                try {
                    const data = await fetchDataFromAPI(url);
                    setContactForm(data);
                    setButtonText(data.button.text);
                    const checkBox = data.fields.find((item:any) => item.type === "checkbox");
                    setCheckBoxData(checkBox);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
    
        fetchData();
    }, [contactForm, id]);
    

    useEffect(() => {
        if (requiredFieldsMissing.length > 0 && contactForm) {
            const missingFieldId = requiredFieldsMissing[0];
            const missingField = contactForm.fields.find(field => field.id === missingFieldId);
            
            if (missingField) {
                const missingFieldLabel = missingField.label;
                setErrorLabel(missingFieldLabel === "Which serviced are you interested?" ? "Please select service type" : `${missingFieldLabel} is required!`)
            }
        }
    }, [requiredFieldsMissing, contactForm]);

    const toggleServiceSelection = (service: string, inputID: number) => {
        setSelectedServices(prevSelectedServices => {
            if (prevSelectedServices.includes(service)) {
                return prevSelectedServices.filter(s => s !== service);
            }
            setDataForm(prevDataForm => ({
                ...prevDataForm,
                [inputID]: selectedServices,
            }));
            return [...prevSelectedServices, service];
        });
    };

    const setFormData = (idNumber: string, value: string) => {
        setFormErrors((prevErrors) => {
            console.log('Deleting error for idNumber:', idNumber);
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[idNumber as unknown as keyof typeof prevErrors];
            console.log('Updated errors:', updatedErrors);
            return updatedErrors;
        });
    
        setDataForm((prevDataForm) => ({
            ...prevDataForm,
            [idNumber]: value,
        }));
    };

    const isEmailValid = (email: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const isPhoneValid = (phone: string) => {
        const phonePattern = /^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
        return phonePattern.test(phone);
    };




    const sendFormData = async () => {
        let isValid = true;
        const { fields } = contactForm || {};
        if (fields) {
            const requiredFieldIds = fields
                .filter((field: InputProps) => field.isRequired)
                .map((field: InputProps) => field.id);


            const missingFields = requiredFieldIds.filter(fieldId => !(fieldId in dataForm));

            if (missingFields.length > 0) {
                setRequiredFieldsMissing(missingFields);
                return;
            }
            const allKeysExist = requiredFieldIds.every(key => Object.prototype.hasOwnProperty.call(dataForm, key));
            
            if (!allKeysExist) {
                return;
            }

            const email = dataForm[6];
            const phone = dataForm[8];

            if (email && !isEmailValid(email)) {
                isValid = false;
                setErrorLabel("Invalid email format");
                return;
            }

            if (phone && !isPhoneValid(phone)) {
                isValid = false;
                setErrorLabel("Invalid phone format");
                return;
            }

        }



        const url = `${url_format}form`;
        const payload = { form_id: id, ...dataForm };

        if (!isValid) {
            return;
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Something went wrong");
            }
            setDataForm({});
            setSelectedServices([]);
            setSubmitSuccess(true);
            // setSnackbar({ type: 'success', message: 'THANK YOU!' });
        } catch (error) {
            setSnackbar({ type: 'error', message: 'Something went wrong' });
        }
    }

    return (
        <>
            {
                submitSuccess ?
                    <ThankYouContainer>
                        <ThankYouTitle>Thank you!</ThankYouTitle>
                        <ThankYouContent>
                            Lorem ipsum dolor sit amet consectetur.
                            Nunc pellentesque egestas quis tincidunt neque integer fringilla.
                            Arcu purus facilisi auctor facilisi adipiscing.
                        </ThankYouContent>
                    </ThankYouContainer> :
                    <Container className="ContactForm" style={{ pointerEvents: buttonText === 'Sending...' ? 'none' : 'all' }}>
                        <Heading sx={[globalSettings.FontProperty.TextBold]}>{title}</Heading>
                        <Box marginTop='60px' marginBottom="20px">
                            <ChipHeading>
                                {checkBoxData?.label}
                            </ChipHeading>
                            <Box sx={{
                                display: 'flex',
                                gap: '8px',
                                flexWrap: 'wrap'
                            }}>
                                {checkBoxData?.choices.map((service, index) =>
                                    <Chip
                                        key={index}
                                        label={service.text}
                                        selected={selectedServices.includes(service.value)}
                                        onClick={() => toggleServiceSelection(service.value, checkBoxData.id)}
                                    />
                                )}
                            </Box>
                        </Box>
                        <MissingText>{errorLabel}</MissingText>
                        <Form>
                           
                            {contactForm?.fields.map((item, key) =>
                                    <Input
                                        {...item}
                                        errorMessage={formErrors[item.id]}
                                        onChange={(data) => setFormData(item.id.toString(), data.target.value)}
                                        key={key}
                                    />
                            )}
                        </Form>
                        {buttonText ? <SubmitButton onClick={sendFormData} label={buttonText} /> : null}
                    </Container>
            }



        </>
    )
}
