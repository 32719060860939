import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

const iconStyling = (iconType: string) => {
  switch (iconType) {
    case 'Helm':
      return {
        desktop: {
          height: pxToRem(944),
          width: pxToRem(944),
          right: '-7%',
          top: '1%',
        },
        mobile: {
          height: pxToRem(438),
          width: pxToRem(452),
          right: '-27%',
          top: '-1%',
        },
      };
    case 'Anchor':
      return {
        desktop: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: '-27%',
          top: '-1%',
        },
        mobile: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: 0,
          top: 0,
        },
      };
    case 'Sea Mew':
      return {
        desktop: {
          height: pxToRem(415),
          width: pxToRem(772),
          left: '-10%',
          top: '25%',
        },
        mobile: {
          height: pxToRem(185),
          width: pxToRem(343),
          right: '-2%',
          top: '1%',
        },
      };
    default:
      return {};
  }
};
type ContainerProps = {
  backgroundColor: string;
};
export const Container = styled(Box)<ContainerProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {},
}));
export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${pxToRem(216)} ${pxToRem(globalSettings.Container.xl)}`,
  paddingBottom: pxToRem(80),
  gap: pxToRem(184),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(24),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.md)} ${pxToRem(50)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${pxToRem(98)} ${pxToRem(globalSettings.Container.md)} ${pxToRem(50)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    gap: pxToRem(24),
    padding: `${pxToRem(130)} ${pxToRem(globalSettings.Container.lg)}`,
    paddingBottom: pxToRem(50),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    gap: pxToRem(80),
    padding: `${pxToRem(150)} ${pxToRem(globalSettings.Container.xl)}`,
    paddingBottom: pxToRem(50),
  }
}));
export const SecondaryBackground = styled(Box)<ContainerProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  height: pxToRem(300),
  position: 'absolute',
  width: '100%',
  bottom: 0,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    height: pxToRem(40),
  },
  [theme.breakpoints.between('md', 'lg')]: {},
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
  height: pxToRem(786),
  width: '100%',
  marginLeft: pxToRem(globalSettings.Container.xl),
  position: 'relative',
  zIndex: 99,
  [theme.breakpoints.down('md')]: {
    height: pxToRem(258),
    marginLeft: pxToRem(globalSettings.Container.md),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: pxToRem(globalSettings.Container.lg),
    height: pxToRem(470),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    height: pxToRem(600),
  },
}));
export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '45%',
  position: 'relative',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
export const RightSideWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: pxToRem(41),
  paddingTop: pxToRem(167),
  justifyContent: 'space-between',
  width: '55%',
  position: 'relative',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
    paddingTop: 0,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
    paddingTop: 0,
    flexDirection: 'column',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    paddingTop: 0,
    flexDirection: 'column',
  },
}));

type MaskWrapperProps = {
  iconcolor: string;
  icontype: string;
};
export const MaskWrapper = styled(motion(Box))<MaskWrapperProps>(
  ({ theme, iconcolor, icontype }) => ({
    ...iconStyling(icontype).desktop,
    position: 'absolute',
    backgroundColor: 'transparent',
    opacity: 0.03,
    zIndex: 1,
    '& svg': {
      height: '100%',
      width: '100%',
    },
    '& svg path': {
      stroke: iconcolor,
      fill: iconcolor,
    },
    [theme.breakpoints.down('md')]: {
      ...iconStyling(icontype).mobile,
    },
    [theme.breakpoints.between('md', 'lg')]: {},
  })
);


export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(76),
  lineHeight: '120%',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(48),
    width: '100%',
    lineHeight: pxToRem(56),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: pxToRem(32),
    width: '100%',
    lineHeight: pxToRem(42),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
    fontSize: pxToRem(60),
    lineHeight: pxToRem(68)
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: pxToRem(60),
    lineHeight: pxToRem(68)
  }
}));
export const Description = styled(Typography)(({ theme }) => ({
  fontFamily: 'Public Sans',
  width: '100%',
  color: globalSettings.Colors.secondary,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
    opacity: 0.7,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24)
  },
  '& li': {
    opacity: 0.7,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24)
  },
  '& h5': {
    fontSize: pxToRem(24),
    fontWeight: 400,
    lineHeight: '120%',
  },
  [theme.breakpoints.down('md')]: {
    '& p': {
    opacity: 1,
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
