import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';
import getIconAnimation from 'src/utils/getIconAnimation';
import { fetchDataFromAPI, url_format } from 'src/atoms/apiConfig';
import { ReactComponent as LocationSVG } from '../../assets/icons/location.svg';
import { ReactComponent as SingleAnchorSVG } from '../../assets/icons/singleAnchor.svg';
import Carousel from './components/Carousel';
import Location from './components/Location';
import {
    Heading,
    Content,
    Container,
    ContentWrapper,
    SubTitle,
    SubTitleContainer,
    LeftContainer,
    RightContainer,
    MaskWrapper
} from './styles';
import './index.css'

const LOCATION_ENDPOINT = "locations"

interface DataProps {
    title: {
        rendered: string;
    };
    acf: {
        LocationImage: string;
        LocationAddress: string
    }
}


interface LocationCarouselProps {
    LCContent: string;
    LCSubtitle: string;
    LCTitle: string;
}
export default function LocationCarousel({ LCContent, LCSubtitle, LCTitle }: LocationCarouselProps) {
    const [data, setData] = useState<DataProps[]>([]);

    useEffect(() => {
        const fetchLocations = async () => {
            const endpoint = `${url_format}${LOCATION_ENDPOINT}`
            const { data: res } = await fetchDataFromAPI(endpoint);
            setData(res)
        }
        fetchLocations();
    }, [])


    return (
        <Container className='LocationCarousel'>
            <MaskWrapper
                initial={{ rotate: 0, x: 0 }}
                animate={getIconAnimation("Anchor")}
            >
                <SingleAnchorSVG />
            </MaskWrapper>
            <ContentWrapper>
                <LeftContainer>
                    <SubTitleContainer>
                        <LocationSVG />
                        <SubTitle>{LCSubtitle}</SubTitle>
                    </SubTitleContainer>
                    <Heading dangerouslySetInnerHTML={{ __html: LCTitle }} />
                    <Content dangerouslySetInnerHTML={{ __html: LCContent }} />
                    <Grid container spacing={3} >
                        {
                            data?.map((location, index) =>
                                <Location 
                                key={index} 
                                url={location.acf.LocationAddress} 
                                index={index} 
                                title={location.title.rendered}
                                />
                            )
                        }
                    </Grid>
                </LeftContainer>

                <RightContainer>
                    <Carousel data={data} />
                </RightContainer>
            </ContentWrapper>
        </Container>
    )
}
