import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Box, Grid, Typography, styled } from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import CountUp from "src/components/CountUp";
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'


const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(52),
    lineHeight: pxToRem(64),
    color: globalSettings.Colors.primary,
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    '& div,fontsninja-text': {
       fontFamily: 'Barlow'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(42),
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',

    },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: globalSettings.Colors.red,
    '& p': {
        display: 'inline',
        margin: 0,
        padding: 0,
    },
    fontWeight: 700,
    fontFamily: 'Public Sans',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(22),
    },
    [theme.breakpoints.between('md', 'lg')]: {
        textAlign: 'center',

    },
}));

interface DataItem {
    DBDataNumber: number;
    DBDataItem: string;
}

interface Props {
    data: DataItem[];
}


const DataGrid: React.FC<Props> = ({ data }) => {

    const renderData = (dataItem: DataItem, index: number, size: number) => (
        <Grid key={index} item lg={size} sm={6} xs={6}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box sx={{ display: 'flex' }}>
                    <Title sx={[globalSettings.FontProperty.TextBold]}>
                        {/* {formatNumber(dataItem.DBDataNumber)} */}
                        <CountUp from={0} to={dataItem.DBDataNumber} />
                    </Title>
                    <Plus />
                </Box>
                <SubTitle>{dataItem.DBDataItem}</SubTitle>
            </Box>
        </Grid>
    );

    const getDataSizes = (numData: number): number[] => {
        switch (numData) {
            case 0:
                return [];
            case 1:
                return [12];
            case 2:
                return [6, 6];
            case 3:
                return [4, 4, 4];
            case 4:
                return [3, 3, 3, 3];
            default:
                return []
        }
    };

    const dataSizes = getDataSizes(data.length);

    return (
        <Grid container spacing={3}>
            {data.map((dataItem, index) => renderData(dataItem, index, dataSizes[index]))}
        </Grid>
    );
};

export default DataGrid;
