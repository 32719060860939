import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import { FooterMenuProps } from 'src/interface/FooterMenu.interface';


export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: pxToRem(23),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const MenuTitle = styled(Link)(({ theme }) => ({
  color: globalSettings.Colors.secondary,
  textDecoration: 'none',
  fontSize: pxToRem(14),
  fontWeight: 600,
  fontFamily: 'Public Sans',
  lineHeight: pxToRem(22),
  cursor: 'pointer',
  '&:hover': {
    color: globalSettings.Colors.red,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(17),
    width: '100%',
    lineHeight: pxToRem(26),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const SubMenuTitle = styled(Link)(({ theme }) => ({
  color: globalSettings.Colors.secondary,
  textDecoration: 'none',
  fontSize: pxToRem(12),
  fontWeight: 400,
  fontFamily: 'Public Sans',
  lineHeight: pxToRem(18),
  display: 'block',
  cursor: 'pointer',
  '&:hover': {
    color: globalSettings.Colors.red,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(14),
    width: '100%',
    lineHeight: pxToRem(22),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));


interface MenuProps {
  navigationData: FooterMenuProps[];
}

const NavigationLayout: React.FC<MenuProps> = ({ navigationData }) => {

  if (navigationData) {

    return (
      <Container>
        {navigationData.map((menu, indexKey) =>
          <Box key={indexKey}>
            <MenuTitle key={indexKey} to={menu.url}>{menu.title}</MenuTitle>
            <Box sx={{ display: 'flex', gap: pxToRem(12), flexDirection: 'column', marginTop: pxToRem(12) }}>
              {menu.child_items?.map((item, index) =>

                <SubMenuTitle
                  key={index}
                  to={item.url}
                  dangerouslySetInnerHTML={{ __html: item.title }} />

              )}
            </Box>
          </Box>
        )}
      </Container>
    )

  }

  return null

};

export default NavigationLayout;
