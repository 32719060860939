import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { rectangleMaskPosition } from 'src/theme/rectangleMaskPosition';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';


type ContainerProps = {
  backgroundColor: string;
};
export const Container = styled(Box)<ContainerProps>(({ theme,backgroundColor }) => ({
  backgroundColor,
  position: 'relative',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: globalSettings.Container.xl,
  position: 'relative',
  zIndex: 99,
  gap: pxToRem(318),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(56),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.md)}`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.lg)}`,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    gap: pxToRem(100),
    padding: `${pxToRem(64)} ${pxToRem(globalSettings.Container.lg)}`,
  },
}));





export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(52),
  lineHeight: pxToRem(64),
  marginBottom: pxToRem(134),
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    lineHeight: pxToRem(42),
    marginBottom: pxToRem(24),
  },
  [theme.breakpoints.between('md', 'lg')]: {
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  paddingTop: pxToRem(20),
  fontSize: pxToRem(16),
  fontFamily: 'Public Sans',
  '& h5': {
    fontSize: pxToRem(24),
    opacity: 1,
    lineHeight: '120%',
    maxWidth: '73%',
    fontWeight: 500,
  },
  '& p': {
    opacity: 0.7,
    lineHeight: pxToRem(24),
    maxWidth: '84%',
    fontWeight: 400,
  },
  '& li': {
    opacity: 0.7,
    lineHeight: pxToRem(24),
    maxWidth: '84%',
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: 0,
    '& h5': {
      marginTop: 0,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(24),
      maxWidth: '100%',
      fontWeight: 500,
    },
    '& p': {
     maxWidth: '100%'
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    '& h5': {
    
      maxWidth: '100%',
    },
    '& p': {
     maxWidth: '100%'
    },
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    '& h5': {
    
      maxWidth: '100%',
    },
    '& p': {
     maxWidth: '100%'
    },
  },
}));


export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '39%',
  [theme.breakpoints.down('md')]: {
   width: '100%'
  },
  [theme.breakpoints.between('md', 'lg')]: {
   width: '50%'
  },
}));


export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down('md')]: {
  },
  [theme.breakpoints.between('md', 'lg')]: {
  },
}));

export const RightSideWrapper = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '50%'
  },
}));

type RectangleProps = {
  backgroundColor: string;
  positiontype: string;
};

export const Rectangle = styled(Box)<RectangleProps>(
  ({ theme, backgroundColor, positiontype }) => ({
     ...rectangleMaskPosition(positiontype).desktop,
    bottom: '-12% !important',
    backgroundColor,
    width: pxToRem(241),
    height: pxToRem(131),
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: pxToRem(130),
      height: pxToRem(42),
      ...rectangleMaskPosition(positiontype).mobile,
      bottom: '-4% !important',
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      bottom: '-4.3%',
    },
   
    [theme.breakpoints.between('md', 'lg')]: {
    },
  })
);
