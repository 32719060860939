import React from 'react'
import { Box, Typography, styled } from '@mui/material';
import pxToRem from 'src/utils/functions/pxToRem';
import globalSettings from 'src/constants/globalSettings';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/questionIcon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phoneIcon.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clockIcon.svg';


const Container = styled(Box)(({ theme }) => ({
  paddingBottom: pxToRem(24),
  display: 'flex',
  gap: pxToRem(16),
  
  [theme.breakpoints.down('md')]: {
   
  },
}));

const SVGContainer = styled(Box)(({ theme }) => ({
  width: '28px',
  '& svg': {
    height: pxToRem(28),
    width: pxToRem(28)
  },
  '& svg path': {
    stroke: globalSettings.Colors.primary,
    strokeWidth: 0
  },
  [theme.breakpoints.down('md')]: {
    // width: '5%',
  },
}));


const Title = styled(Typography)(({ theme }) => ({
  lineHeight: pxToRem(28),
  color: globalSettings.Colors.primary,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
 
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    width: '100%',
    marginBottom: pxToRem(4)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',

  },
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(14),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(22),
  opacity: 0.7,
  cursor: 'pointer',
  color: globalSettings.Colors.primary,
  '&:hover':{
    color: globalSettings.Colors.red
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    width: '100%',
    paddingBottom: pxToRem(10)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',

  },
}));

const StaticContent = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(14),
  fontFamily: 'Public Sans',
  fontWeight: 400,
  lineHeight: pxToRem(22),
  opacity: 0.7,
  color: globalSettings.Colors.primary,
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(18),
    width: '100%',
    paddingBottom: pxToRem(10)
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',

  },
}));

interface DetailsProps {
  BPhoneName?: string;
  BAddress?: string
}

interface AddressProps {
  BAddress: string
}

interface PhoneProps {
  BPhoneName: string
  BPhoneNumber: string
}


interface InfoProps {
  careerEmail: string;
  address: AddressProps[]
  phone: PhoneProps[]
  workingHours: string;
  email: string;

}
export default function Info({ careerEmail, address, phone,workingHours,email }: InfoProps) {


  const _navigate = (url: string) => {
    window.open(url,'_blank')
  };


  return (
    <>
      <Container>
        <SVGContainer>
          <QuestionIcon/>
        </SVGContainer>
        <Box width="90%">
          <Title sx={[globalSettings.FontProperty.TextSemiBold]}>Career enquiries</Title>  
          <Content onClick={()=>_navigate(`mailto:${careerEmail}`)}>{careerEmail}</Content>
        </Box>    
      </Container>
      
      <Container>
        <SVGContainer>
        <LocationIcon className='location'/>
        </SVGContainer>
        <Box width="90%">
          <Title 
          sx={[globalSettings.FontProperty.TextSemiBold]}
          >
            Visit us
          </Title>  
          {address.map((item,index)=>
          <Content onClick={()=>_navigate(`http://maps.google.com/?q=${item.BAddress}`)} key={index}>{item.BAddress}</Content>
          )}
        </Box>    
      </Container>

      <Container>
        <SVGContainer>
        <PhoneIcon/>
        </SVGContainer>
       
        <Box width="90%">
          <Title sx={[globalSettings.FontProperty.TextSemiBold]}>Call us</Title>  
          {phone.map((item,index)=>
          <Content onClick={()=>_navigate(`tel:${item.BPhoneNumber}`)} key={index}>{item.BPhoneName} {item.BPhoneNumber}</Content>
          )}
        </Box>    
      </Container>

      <Container>
        <SVGContainer>
        <MailIcon/>
        </SVGContainer>
       
        <Box width="90%">
          <Title sx={[globalSettings.FontProperty.TextSemiBold]}>Talk to us</Title>  
          <Content onClick={()=>_navigate(`mailto:${email}`)}>{email}</Content>
        </Box>    
      </Container>

      <Container>
        <SVGContainer>
        <ClockIcon/>
        </SVGContainer>
       
        <Box width="90%">
          <Title sx={[globalSettings.FontProperty.TextSemiBold]}>Working Hours</Title>  
          <StaticContent>{workingHours}</StaticContent>
        </Box>    
      </Container>
    </>

  )
}
