import { Box, Typography, styled } from "@mui/material";
import globalSettings from "src/constants/globalSettings_backup";
import { ReactComponent as UploadIconSvg } from "../../assets/icons/upload.svg";
import { ReactComponent as CrossSvg } from "../../assets/icons/cross.svg";
import {ReactComponent as PlusSvg} from '../../assets/icons/plus.svg'

export const Container = styled(Box)`
    display: flex;
    flex-flow: column;
    width: 100%;
`

export const Title = styled('label')`
    color: #fff;
    font-weight: 700;
    font-family: Public sans;
    font-size: 1rem;
    line-height: 1.125.rem;
    @media (max-width: 768px) {
        font-size: 3.6vw;
    }
`

type InputStyleProps = {
    placeholderColor?: string;
    placeholderOpacity?: number;
}

export const InputStyle = styled('input')(({placeholderColor = '#fff', placeholderOpacity = 0.5} : InputStyleProps) => `
    all: unset;
    background-color: #1C2A4E;
    border-radius: 0.5rem;
    padding: 16px 12px;
    font-size: 1rem;
    color: #fff;
    &::placeholder {
        color: #EEE7DC;
    }
    @media (max-width: 768px) {
    }
`)

export const Required = styled('span')`
    color: #007EF0;
`

export const SelectContainer = styled(Box)`
    position: relative;
    cursor: pointer;
`

export const SelectArrow = styled('div')<OptionProps>(({selected})=>`
    position: absolute;
    top: 42%;
    right: 1.2vw;
    transform: translateY(-50%);
    width: 0.4vw;
    height: 0.4vw;
    display: inline-block;
    pointer-events: none;
    z-index: 1;
    background-color: transparent;
    border-style: solid;
    border-width: 0;
    transform: ${selected ? 'rotate(225deg) translate(-0.15vw, -0.15vw)' : 'rotate(45deg)'};
    transition: 0.2s;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-style: solid;
        border-width: 0.15vw 0.15vw 0 0;
        transform: rotate(90deg);
        border-color: ${selected ? globalSettings.Colors.primary : 'rgba(0,0,0,0.5)'};
    }

    @media (max-width: 768px) {
        width: 2vw;
        height: 2vw;
        top: 48%;
        right: 2.3vw;
        &::before {
            border-width: 0.75vw 0.75vw 0 0;
        }
    }
`);

export const SelectedOption = styled(Box)`
    all: unset;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding: 0.4vw 0 0.8vw 0;
    font-size: 1rem;
    width: 100%;
    display: block;
    @media (max-width: 768px) {
        font-size: 3.6vw;
        padding: 2vw 0 2.5vw 0;
    }
`

export const RoundedSelectedOption = styled(Box)`
    all: unset;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding: 0.4vw 0 0.8vw 0;
    font-size: 1rem;
    width: 100%;
    display: block;
    @media (max-width: 768px) {
        font-size: 3.6vw;
        padding: 2vw 0 2.5vw 0;
    }
`

export const SelectStyle = styled(Box)`
    position: absolute;
    top: 3.5vw;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    width: 100%;
    z-index: 9;
    @media (max-width: 768px) {
        top: calc(100% + 2.5vw);
    }
`

export const RoundedSelectOptionsStyle = styled(Box)`
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9;
    background-color: #ECF6FE;
`

type OptionProps = {
    selected?: boolean;
    backgroundColor?: string;
    textcolor?: string;
}

export const Option = styled(Box)<OptionProps>(({selected, backgroundColor = '#FFFFFF', textcolor = 'rgba(0,0,0,0.5)'}) => `
  color: ${selected ? '#FFFFFF' : textcolor};
  background-color: ${selected ? globalSettings.Colors.primary : backgroundColor};
  border: none;
  padding: 0.4vw 0.8vw;
  border: 0.2vw solid transparent;
  cursor: pointer;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 3.6vw;
    padding: 2vw 2vw 2.5vw 2vw;
    border: 1vw solid transparent;
  }

  &:hover {
    border-color: #007EF0;
    background-color: ${selected ? globalSettings.Colors.primary : '#ECF6FE'};
    color: ${selected ? '#FFFFFF' : '#1D2E50'}
  }

  &:focus {
    background-color: ${globalSettings.Colors.primary};
  }

  & > div:nth-of-type(1) {
    border-radius: 10px 10px;
  }

`);

export const RoundedOption = styled(Box)<OptionProps>(({selected, backgroundColor = '#FFFFFF', textcolor = 'rgba(0,0,0,0.5)'}) => `
  color: ${selected ? '#FFFFFF' : textcolor};
  background-color: ${selected ? globalSettings.Colors.primaryLight3 : '#ECF6FE'};
  border: none;
  padding: 0.4vw 0.8vw;
  border: 0.2vw solid transparent;
  cursor: pointer;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 3.6vw;
    padding: 2vw 2vw 2.5vw 2vw;
    border: 1vw solid transparent;
  }

  &:hover {
    border-color: ${globalSettings.Colors.primaryLight3};
    background-color: ${selected ? globalSettings.Colors.primaryLight3 : '#ECF6FE'};
    color: ${selected ? '#FFFFFF' : '#1D2E50'}
  }

  &:focus {
    background-color: ${globalSettings.Colors.primary};
  }

  & > div:nth-of-type(1) {
    border-radius: 10px 10px;
  }

`);

export const TextareaStyle = styled('textarea')`
    all: unset;
    background-color: #1C2A4E;
    color: #fff;
    border-radius: 0.5rem;
    padding: 16px 12px;
    font-size: 1rem;
    &::placeholder {
        color: #EEE7DC;
        font-size: 1rem;
    }
    @media (max-width: 768px) {
       
    }
`

export const RoundedInputStyle = styled('input')`
    all: unset;
    background-color: white;
    color: black;
    padding: 0.65vw 1.5vw 0.65vw 2.4vw;
    font-size: 1rem;
    border-radius: 100vw 100vw;
    width: 100%;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
        font-size: 3.6vw;
        padding: 3.5vw 3.5vw 3.5vw 11.5vw;
    }
`

export const RoundedInputContainer = styled(Box)`
    position: relative;
    display: flex;
    align-items: center;
    svg {
        pointer-events: none;
        position: absolute;
        left: 1.1vw;
        width: 0.9vw;
        height: 0.9vw;
        stroke: #42424D;
        opacity: 0.5;
        path {
            stroke: #42424D;
        }
    }
    @media (max-width: 768px) {
        svg {
            left: 5vw;
            width: 4vw;
            height: 4vw;
        }
    }
`

export type RoundedSelectStyleProps = {
    bordercolor?: string;
    activetextcolor?: string;
    arrowcolor?: string;
    selected?: boolean;
}

export const RoundedSelectStyle = styled(Box)<RoundedSelectStyleProps>(({bordercolor = 'rgba(255,255,255,0.5)', activetextcolor = 'white', arrowcolor = 'white', selected})=>`
    position: relative;
    cursor: pointer;
    background-color: ${selected ? globalSettings.Colors.primaryLight3: 'transparent'};
    border: ${selected ? 'none' : `1px solid ${bordercolor}`};
    border-radius: ${selected ? '1.5vw 1.5vw 0 0' : '100vw 100vw'};
    & > div:nth-of-type(1){
        right: 1.3vw;
        top: 40%;
        width: 0.4vw;
        height: 0.4vw;
        color: ${selected ? 'white' : arrowcolor};
        z-index: 1;
        &::before {
            border-color: ${selected ? 'white' : arrowcolor};
        }
    }
    & > div:nth-of-type(2){
        border: none;
        color: ${selected ? 'white' : activetextcolor};
        padding: 0.65vw 3vw 0.65vw 1.5vw;
        font-size: 0.75vw;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    & > div:nth-of-type(3){
        max-height: 50vh;
        overflow-y: overlay;
        border-radius: 0 0 1.5vw 1.5vw;
        z-index: 9;
    }
    & > div:nth-of-type(3) > div:last-child{
        border-radius: 0 0 1.5vw 1.5vw;
    }
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border-radius: ${selected ? '8vw 8vw 0 0' : '100vw 100vw'};
        & > div:nth-of-type(1) {
            width: 2vw;
            height: 2vw;
            top: 42%;
            right: 8vw;
            &::before {
                border-width: 0.75vw 0.75vw 0 0;
            }
        }
        & > div:nth-of-type(2){
            font-size: 3.6vw;
            padding: 3.5vw 5vw 3.5vw 11.5vw;
        }
        & > div:nth-of-type(3){
            border-radius: 0 0 8vw 8vw;
        }
        & > div:nth-of-type(3) > div:last-child{
            border-radius: 0 0 8vw 8vw;
        }
    }
`)

export const CheckBoxesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CheckBoxesWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 1vw;
`;

export const HTMLBlockStyle = styled(Box)``

export const SectionLine = styled('hr')`
    all: unset;
    width: 100%;
    height: 1px;
    background-color: ${globalSettings.Colors.primaryLight3};
    margin: 24px 0;
`

export const MultiSelectedOption = styled(Box)`
    all: unset;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding: 0.4vw 0 0.8vw 0;
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    gap: 1vw;
    padding-right: 3vw;
    box-sizing: border-box;
    @media (max-width: 768px) {
        font-size: 3.6vw;
        padding: 2vw 0 2.5vw 0;
    }
`

export const ChipStyle = styled(Box)`
    display: flex;
    gap: 0.8vw;
    width: fit-content;
    height: fit-content;
    align-items: center;
    transition: 0.1s;
    stroke: ${globalSettings.Colors.bodyBlack};
    svg path {
        stroke-width: 0.15vw;
        transform: scale(0.9) translate(5%, 5%)
    }
    &:hover {
        color: ${globalSettings.Colors.red};
        stroke: ${globalSettings.Colors.red};
    }
    & > * {
        pointer-events: none;
    }
`

export const ChipText = styled(Typography)`
    font-size: 0.9vw;
    @media (max-width: 768px) {
        font-size: 3.6vw;
    }
`

export const ChipX = styled(CrossSvg)`
    width: 1vw;
    height: 1vw;
    stroke: inherit;
    path {
        stroke: inherit;
    }
`

export const AddressContainer = styled(Box)`
    display: flex;
    flex-flow: column;
    width: 100%;
    position: relative;
`

export const AddressOptions = styled(Box)`
    margin-top: 1vw;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 1vw;
    width: 100%;
`

export const AddressOption = styled(Box)`
    cursor: pointer;
    padding: 0.5vw 1vw;
    border: 0.2vw solid transparent;
    color: ${globalSettings.Colors.labelBlack};
    &:hover {
        background-color: ${globalSettings.Colors.primaryLight2};
        color: ${globalSettings.Colors.bodyBlack};
        border-color: ${globalSettings.Colors.primary};
    }
    &:first-of-type {
        border-radius: 1vw 1vw 0 0;
    }
    &:last-of-type {
        border-radius: 0 0 1vw 1vw;
    }
`

export const PhoneContainer = styled(Box)`
    .react-tel-input {
        box-sizing: border-box;
        input {
            all: unset;
            width: 100%;
            border-bottom: 1px solid rgba(0,0,0,0.5);
            padding: 12px 0;
            font-weight: 400;
            text-transform: initial;
            padding-left: 48px;
            box-sizing: border-box;
        }
        .flag-dropdown {
            border: none;
            background-color: transparent;
        }
        .flag-dropdown.open {
            background: transparent;
        }
    }
`

export const UploadButton = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 300px
    height: 200px;
    background-color: transparent;
    border: 1px solid ${globalSettings.Colors.primary};
    border-radius: 1vw 1vw;
    gap: 24px;
    cursor: pointer;
    transition: 0.2s;
    user-select: none;
    &:hover {
        background-color: ${globalSettings.Colors.primary};
        & > svg {
            path, polyline, line {
                stroke: white;
            }   
        }
        & > p {
            color: white;
        }
    }
`

export const UploadText = styled(Typography)`
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: ${globalSettings.Colors.primary};
`

export const UploadIcon = styled(UploadIconSvg)`
    width: 30px;
    height: 30px;
    path, polyline, line {
        stroke: ${globalSettings.Colors.primary};
    }
`

export const FileInput = styled('input')`
  display: none;
`;

export const ListContainer = styled(Box)``

export const ListInputContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 12px;
`

export const ListInput = styled('input')`
    all: unset;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding: 12px 0;
    width: 100%;
    &::placeholder {
        opacity: 0.5
    }
`

export const AddListItemButton = styled(PlusSvg)`
    width: 30px;
    height: 30px;
    border: 2px solid ${globalSettings.Colors.primary};
    border-radius: 100%;
    padding: 5px;
    fill: ${globalSettings.Colors.primary};
    cursor: pointer;
`

export const ListItemsContainer = styled(Box)`
    display: flex;
    flex-flow: column;
    gap: 12px;
    margin-top: 1vw;
`

export const ListItem = styled(Box)`
    box-shadow: 0px 0px 30px -20px rgba(0, 0, 0, 0.5);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ListItemText = styled(Box)``

export const RemoveListItemButton = styled(CrossSvg)`
    width: 1vw;
    height: 1vw;
    border-radius: 100%;
    cursor: pointer;
    path {
        stroke: ${globalSettings.Colors.red};
    }
`

export const ConsetContainer = styled(Box)`
    display: flex;
    flex-flow: column;
`

export const ConsetText = styled(Typography)`
    user-select: none;
`

export const PageNavigationContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
`

export const PageNavigation = styled(Box)`
    padding: 12px 48px;
    background-color: ${globalSettings.Colors.primary};
    color: white;
    border-radius: 10px;
    cursor: pointer;
`