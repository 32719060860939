const globalSettings = {
  "websiteName" : "Piolet Partners",
  "Colors" : {
    "primary" : "#007EF0",
    "primaryLight1" : "#ECF6FE",
    "primaryLight2" : "#D7EFFC",
    "primaryLight3" : "#71B4F7",
    "primaryLight4" : "#F2F9FD",
    "primaryOpacity5" : "rgba(0, 126, 240, 0.05)",
    "primaryOpacity60" : "rgba(0, 126, 240, 0.6)",
    "secondary" : "#0B0F66",
    "secondaryOpacity50" : "rgba(29, 46, 81, 0.5)",
    "white" : "#FFFFFF",
    "whiteOpacity10" : "rgba(255, 255, 255, 0.1)",
    "whiteOpacity50" : "rgba(255, 255, 255, 0.5)",
    "bodyBlack" : "#1D2E50",
    "labelBlack" : "rgba(29, 46, 80, 0.5)",
    "grey" : "#EAEFF3",
    "red" : "#FF7D78",
    "redLight" : "#F7DDDC",
    "yellow" : "#E8B35C",
    "yellowLight" : "#F2E8D6",
    "green" : "#28A745",
    "greenLight" : "#CAEBE3",
  },
  "FontProperty" : {
    "TextBold": {
      "fontFamily": "Barlow",
      "fontWeight": 700
    },
    "TextSemiBold": {
      "fontFamily": "Barlow",
      "fontWeight": 600
    },
    "TextMedium": {
      "fontFamily": "Barlow",
      "fontWeight": 500
    },
    "TextRegular": {
      "fontFamily": "Barlow",
      "fontWeight": 500
    },
    "TextLight": {
      "fontFamily": "Barlow",
      "fontWeight": 300
    },
    "TextColorPrimary": {
      "color": "#007EF0"
    },
    "TextColorSecondary": {
      "color": "#0B0F66"
    },
    "TextColorWhite": {
      "color": "#FFFFFF"
    },
    "TextColorBlack": {
      "color": "#1D2E50"
    },
    "TextColorGrey": {
      "color": "#EAEFF3"
    },
    "TextColorGreen": {
      "color": "#28A745"
    },
    "TextColorRed": {
      "color": "#FF7D78"
    },
    "h1": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 80,
      "lineHeight": 88
    },
    "h2": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 64,
      "lineHeight": 72
    },
    "h3": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 40,
      "lineHeight": 48
    },
    "h4": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 32,
      "lineHeight": 40
    },
    "h5": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 28,
      "lineHeight": 38
    },
    "h6": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 600,
      "fontSize": 24,
      "lineHeight": 30
    },
    "p": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 400,
      "fontSize": 16,
      "lineHeight": 24,
      "color": "#1D2E50"
    },
    "label": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 700,
      "fontSize": 16,
      "lineHeight": 20,
      "color": "#0B0F66"
    },
    "placeholder": {
      "fontFamily": "EuclidCircularA",
      "fontWeight": 400,
      "fontSize": 18,
      "lineHeight": 23,
      "color": "rgba(29, 46, 81, 0.5)"
    }
  }
}


export default globalSettings