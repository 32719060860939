import React from 'react'
import { Button as MButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import globalSettings from 'src/constants/globalSettings';
import { ReactComponent as RightArrow } from '../../../assets/icons/rightArrow.svg';

interface ButtonProps{
    label: string
    onClick: () =>  void;
}
export default function SubmitButton({label,onClick}: ButtonProps) {
  return (
    <MButton onClick={onClick} sx={{
  borderRadius: 0,
  textTransform: 'none',
  cursor: 'pointer',
        backgroundColor: globalSettings.Colors.red,
      color: globalSettings.Colors.white,
      padding: '11px 22px',
      maxHeight: '40px',
      '&:hover': {
        backgroundColor: globalSettings.Colors.red,
        '& .MuiButton-endIcon': {
          transition: "padding-left 0.4s ease-in-out 0.2s",
          paddingLeft: '16px',
        },
      },
      '&:active': {
        backgroundColor: '#BD362F',

      },
    }}
    endIcon={<RightArrow/>}
    >
      <Typography sx={{
        fontSize: '15px',
        fontFamily: 'Public Sans',
        fontWeight: 700,
        lineHeight: '26px',
      }}>
      {label}
      </Typography>
       
    </MButton>
  )
}
