import React, {useRef } from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import Slider from "react-slick";
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevronRightLocation.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevronLeft.svg';


const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

const SliderButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: pxToRem(24),
    marginTop: pxToRem(64),
    [theme.breakpoints.down('md')]: {
        marginTop: pxToRem(24),
        justifyContent: 'center',
    },
}));


const SliderButton = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    display: 'grid',
    placeItems: 'center',
    width: '40px',
    padding: '8px',
    height: '40px',
    '& svg':{
        width: '24px',
        height: '24px',
       
    },
    '& svg,path': {
        fill: globalSettings.Colors.red
    },
    '&:hover': {
        backgroundColor: 'rgba(235, 86, 79, 0.08)',
        borderRadius: '50%'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
    },
}));


const SliderImageContainer = styled(Box)(({ theme }) => ({
    minWidth: '340px',
    minHeight: '360px',
    display: 'flex !important',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center',
    alignItems: 'flex-end',
    cursor: 'pointer',
    paddingBottom: pxToRem(24),
    transition: 'opacity 0.3s', // Add a transition for a smooth effect
    '&:hover': {
        opacity: 0.8, // Change opacity on hover
    },
    [theme.breakpoints.down('md')]: {
    },
}));


const SliderImageDescription = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(18),
    fontFamily: 'Barlow',
    lineHeight: pxToRem(28),
    fontWeight: 600,
    color: '#FFF',
    [theme.breakpoints.down('md')]: {
    },
}));


interface DataProps {
    title: {
        rendered: string;
    };
    acf: {
        LocationImage: string;
        LocationAddress: string;
    }
}
interface CarouselData {
    data?: DataProps[]
}
export default function Carousel({ data }: CarouselData) {
    const sliderRef = useRef<Slider>(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: false,
        variableWidth: true,
    }

    const _navigate = (url: string) => {
        const link = `http://maps.google.com/?q=${url}`
        window.open(link,'_blank')
    };

    const slideToNextPost = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const slideToPrevPost = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };
    return (
        <Box sx={{position: 'relative', zIndex: 99}}>
            <Slider {...settings} ref={sliderRef}>
                {data?.map((item, index) =>


                    <SliderImageContainer
                        key={index}
                        onClick={()=>_navigate(item.acf.LocationAddress)}
                        sx={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), 
                            rgba(0, 0, 0, 0.6)), 
                            url(https://${REACT_APP_CMS_HOST}${item.acf.LocationImage})`,
                        }}
                    >
                        <SliderImageDescription>{item.title.rendered}</SliderImageDescription>
                    </SliderImageContainer>
                )}
            </Slider>
            <SliderButtonContainer>
                <SliderButton onClick={slideToPrevPost}>
                    <ChevronLeft />
                </SliderButton>
                <SliderButton onClick={slideToNextPost}>
                    <ChevronRight />
                </SliderButton>
            </SliderButtonContainer>
        </Box>
    )
}
