import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.secondaryLight,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${pxToRem(235)} ${pxToRem(globalSettings.Container.xl)}`,
  paddingBottom: pxToRem(140),
  gap: pxToRem(180),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(56),
    padding: `${pxToRem(106)} ${pxToRem(globalSettings.Container.md)}`,
    paddingBottom: pxToRem(64),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    gap: pxToRem(76),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
 
}));



export const SubTitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: pxToRem(32),
  display: 'flex',
  gap: '8px',
  '& svg': {
    height: pxToRem(18),
    width: pxToRem(18),
},
  '& svg path': {
    stroke: globalSettings.Colors.red,
    fill: globalSettings.Colors.red,
    strokeWidth: 0
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginBottom: pxToRem(24),
  },
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  minWidth: '35%',
  position: 'relative',
  zIndex: 99,
  [theme.breakpoints.down('md')]: {
    minWidth: '100%',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '106%',
    paddingLeft: '16px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    paddingLeft: pxToRem(globalSettings.Container.lg),
    maxWidth: '100vw',
  }
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(48),
  lineHeight: '120%',
  fontWeight: 800,
  textTransform: 'capitalize',
  color: globalSettings.Colors.primary,
  fontFamily: 'Public Sans',
  width: '100%',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    width: '100%',
    textAlign: 'center',
    lineHeight: pxToRem(42),
    fontWeight: 700
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(20),
  fontFamily: 'Public Sans',
  fontWeight: 500,
  lineHeight: pxToRem(24),
  color: globalSettings.Colors.primary,
  marginTop: pxToRem(32),
  marginBottom: pxToRem(56),
  maxWidth: '100%',
  '& p,h6': {
    margin: 0,
    padding: 0,
    display: 'inline'
  },
  
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    textAlign: 'center',
    marginTop: pxToRem(24),
    fontSize: pxToRem(16),
    fontWeight: 400,
    opacity: 0.7,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '100%',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(18),
  fontWeight: 700,
  textTransform: 'uppercase',
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  color: globalSettings.Colors.red,
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    textAlign: 'center',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const MaskWrapper = styled(motion(Box))(
  ({ theme }) => ({
    right: '-2%',
    top: '-23%',
    position: 'absolute',
    backgroundColor: 'transparent',
    opacity: 0.03,
    width: pxToRem(899),
    height: pxToRem(1266),
    zIndex: 1,
    '& svg': {
      height: '100%',
      width: '100%',
    },
    '& svg path': {
      stroke: globalSettings.Colors.primary,
      fill: globalSettings.Colors.primary,
    },
    [theme.breakpoints.down('md')]: {
      left: '-34%',
      right: 0,
      top: '-2%',
      width: pxToRem(516),
      height: pxToRem(752),
    },
    [theme.breakpoints.between('md', 'lg')]: {},
  })
);

