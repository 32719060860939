import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import globalSettings from 'src/constants/globalSettings';
import pxToRem from 'src/utils/functions/pxToRem';

const iconStyling = (iconType: string) => {
  switch (iconType) {
    case 'Helm':
      return {
        desktop: {
          height: pxToRem(1116),
          width: pxToRem(1116),
          left: '-7%',
          top: '12%',
        },
        mobile: {
          height: pxToRem(648),
          width: pxToRem(632),
          left: '-20%',
          top: '5%',
        },
      };
    case 'Anchor':
      return {
        desktop: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: '-27%',
          top: '-1%',
        },
        mobile: {
          height: pxToRem(1280),
          width: pxToRem(883),
          right: 0,
          top: 0,
        },
      };
    case 'Sea Mew':
      return {
        desktop: {
          height: pxToRem(415),
          width: pxToRem(772),
          left: '-10%',
          top: '25%',
        },
        mobile: {
          height: pxToRem(185),
          width: pxToRem(343),
          right: '-2%',
          top: '1%',
        },
      };
    default:
      return {};
  }
};

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: globalSettings.Colors.secondaryLight,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${pxToRem(140)} ${pxToRem(globalSettings.Container.xl)}`,
  paddingTop: pxToRem(129),
  paddingBottom: pxToRem(100),
  gap: pxToRem(180),
  alignItems: 'center',
  position: 'relative',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: pxToRem(34),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.md)}`,
    paddingBottom: pxToRem(49),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: pxToRem(34),
    padding: `${pxToRem(98)} ${pxToRem(globalSettings.Container.md)}`,
    paddingBottom: pxToRem(49),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gap: pxToRem(48),
    padding: `${pxToRem(120)} ${pxToRem(globalSettings.Container.lg)}`,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    paddingTop: pxToRem(160),
    gap: pxToRem(80),
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  height: pxToRem(628),
  width: '100%',
  zIndex: 99,
  marginRight: pxToRem(78),
  marginBottom: pxToRem(64),
  [theme.breakpoints.down('md')]: {
    height: pxToRem(258),
    marginRight: 0,
    marginBottom: pxToRem(14),
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginRight: pxToRem(29),
    height: pxToRem(400),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    height: pxToRem(500),
  },
}));

export const Rectangle = styled(Box)(({ theme }) => ({
  height: pxToRem(293),
  width: pxToRem(437),
  position: 'absolute',
  right: 0,
  bottom: 0,
  zIndex: 1,
  backgroundColor: globalSettings.Colors.primary,
  [theme.breakpoints.down('md')]: {
    height: pxToRem(166),
    width: pxToRem(197),
    right: '-16px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    height: pxToRem(150),
    width: pxToRem(300),
    bottom: '5%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    height: pxToRem(200),
    width: pxToRem(350),
  },
}));

export const LeftSideWrapper = styled(Box)(({ theme }) => ({
  width: '35%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '50%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '45%'
  },
}));
export const RightSideWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '65%',
  position: 'relative',
  zIndex: '-1',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '50%'
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '55%'
  },
}));

type MaskWrapperProps = {
  iconcolor: string;
  icontype: string;
};
export const MaskWrapper = styled(motion(Box))<MaskWrapperProps>(
  ({ theme, iconcolor, icontype }) => ({
    ...iconStyling(icontype).desktop,
    position: 'absolute',
    backgroundColor: 'transparent',
    opacity: 0.03,
    zIndex: 1,
    '& svg': {
      height: '100%',
      width: '100%',
    },
    '& svg path': {
      stroke: globalSettings.Colors.primary,
      fill: globalSettings.Colors.primary,
    },
    [theme.breakpoints.down('md')]: {
      ...iconStyling(icontype).mobile,
    },
    [theme.breakpoints.between('md', 'lg')]: {},
  })
);

export const Heading = styled(Typography)(({ theme }) => ({
  lineHeight: '120%',
  textTransform: 'capitalize',
  width: '100%',
  fontSize: pxToRem(64),
  fontFamily: 'Public Sans',
  fontWeight: 800,
  color: globalSettings.Colors.primary,
  marginBottom: pxToRem(32),
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(42),
    marginBottom: pxToRem(24),
    fontFamily: 'Barlow',
    fontWeight: 700,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontFamily: 'Public Sans',
  width: '100%',
  color: globalSettings.Colors.primary,
  '& p': {
    display: 'inline',
    margin: 0,
    padding: 0,
    opacity: 0.7,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24),
  },
  '& h5,h6': {
    marginTop: 0,
    fontSize: pxToRem(20),
    fontWeight: 500,
    lineHeight: pxToRem(24),
  },
  [theme.breakpoints.down('md')]: {
    '& h5,h6': {
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: pxToRem(24),
      marginBottom: pxToRem(24),
    }
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '100%',
  },
}));
