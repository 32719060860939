import Card from './components/Card';


interface SLServicesProps {
  SLService: number;
  SLServiceBgColour: string;
  SLServiceMaskPosition: string[]
}

interface ServiceListProps {
  SLServices: SLServicesProps[]
}
export default function ServiceList({ SLServices }: ServiceListProps) {
  return (
    <>
      {SLServices?.map((service, index) =>
        <Card key={index} {...service} />
      )}
    </>
  )
}
