import globalSettings from 'src/constants/globalSettings';
import getIcon from 'src/utils/getIcon';
import getIconAnimation from 'src/utils/getIconAnimation';
import Image from 'src/components/Image';
import { Heading, Description, Container, ContentWrapper, MaskWrapper, Rectangle, ImageWrapper, LeftSideWrapper, RightSideWrapper } from './styles';

const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };

interface SecondaryBannerProps {
    SBTitle: string;
    SBTitleSize: string;
    SBContent: string;
    SBImage: string;
    SBMask: string;
}
export default function SecondaryBanner({ SBTitle, SBTitleSize, SBContent, SBImage, SBMask }: SecondaryBannerProps) {
    return (
        <Container className='SecondaryBanner'>
            <MaskWrapper
                icontype={SBMask}
                iconcolor="transparent"
                initial={{ rotate: 0, x: 0 }}
                animate={getIconAnimation(SBMask)}
            >
                {getIcon(SBMask)}
            </MaskWrapper>
            <ContentWrapper>
                <LeftSideWrapper>
                    <Heading
                        dangerouslySetInnerHTML={{ __html: SBTitle }}
                    />
                    <Description
                        dangerouslySetInnerHTML={{ __html: SBContent }}
                    />
                </LeftSideWrapper>
                <RightSideWrapper>
                    <ImageWrapper>
                        <Image
                            src={`https://${REACT_APP_CMS_HOST}${SBImage}`}
                            sx={{
                                height: '100%',
                                width: '100%'
                            }}
                        />
                    </ImageWrapper>
                    <Rectangle />
                </RightSideWrapper>

            </ContentWrapper>
        </Container>
    )
}
