import { useEffect, useState } from 'react';
import globalSettings from 'src/constants/globalSettings';
import useResponsive from 'src/hooks/useResponsive';
import { FONT_COLORS } from 'src/theme/fontColors';
import { 
  Heading, 
  Description, 
  Container, 
  ContentWrapper,
  LeftSideWrapper, 
  RightSideWrapper,
  Rectangle
} from './styles';



interface TextBlockProps {
  TBTitle: string;
  TBContent: string;
  TBBgColour: string;
}
export default function TextBlock({ TBTitle, TBContent, TBBgColour }: TextBlockProps) {
    const isMobile = useResponsive('down', 'md')
    const [color, setColor] = useState("")


    useEffect(() => {
        setColor(FONT_COLORS[TBBgColour as "#0F1F43" | "#EEE7DC" | "#274BA1" | "#F6F3ED"] || "#F6F3ED")
    }, [TBBgColour])
    return (
        <Container backgroundColor={TBBgColour} className='TextBlock'>
           
            <ContentWrapper>
               {
                TBTitle !== "" &&
                <LeftSideWrapper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: TBContent === "" ? 'center' : 'initial',
                  }}
                >
                   <Rectangle />
                <Heading 
                sx={[globalSettings.FontProperty.TextBold,{
                  textAlign: TBContent === "" ? 'center' : 'initial',
                  width: TBContent === "" ? '100%' : '79%',
                  color,
                }]}
                dangerouslySetInnerHTML={{__html:TBTitle}}
                />
                </LeftSideWrapper>
               }
                   
               {
                TBContent !=="" &&
                <RightSideWrapper>
                <Description
                dangerouslySetInnerHTML={{__html:TBContent}}
                sx={{
                  color,
                  textAlign: TBTitle === "" ? 'center' : 'initial'
                }}
               
              />
               </RightSideWrapper>
               }
            </ContentWrapper>
        </Container>
    )
}
