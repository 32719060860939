// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import { RecoilRoot } from 'recoil';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes';
// theme
import ThemeProvider from 'src/theme';
// components
import ScrollToTop from 'src/components/ScrollToTop';
import { ThemeSettings, SettingsProvider } from 'src/components/Settings';

export default function App() {

  const [metaTags, setMetaTags] = useState(null);

  // useEffect(() => {
  //   fetch('https://automation.orangedigital.au/api/v1.0/pilot-partners-development/settings/')
  //     .then((response) => response.json())
  //     .then((data) => setMetaTags(data.settings_script_header.replaceAll(/\n/g, '')));
  // }, []);

  // useEffect(() => {
  //   if (metaTags) {
  //     const head = document.head;

  //     const parser = new DOMParser();

  //     const metaElements = parser.parseFromString(metaTags, 'text/html').head.children
  //     if(metaElements){
  //       for(let i = 0; i < metaElements.length; i+=1){
  //         const metaTagElement = document.createElement('meta');
  //         const metaElement = metaElements[i]
  //         if (metaElement) {
  //           const nameAttr = metaElement.getAttribute('name');
  //           const contentAttr = metaElement.getAttribute('content');
  //           if (nameAttr && contentAttr) {
  //             metaTagElement.setAttribute('name', nameAttr);
  //             metaTagElement.setAttribute('content', contentAttr);
  //             head.appendChild(metaTagElement);
  //           }
  //         }
  //       }
  //     }

  //   }
  // }, [metaTags]);


  return (
    <HelmetProvider>
      <RecoilRoot>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <ThemeProvider>
                <ThemeSettings>
                    <Router />
                </ThemeSettings>
              </ThemeProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </RecoilRoot>
    </HelmetProvider>
  );
}
