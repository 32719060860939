import { Box, styled, Typography } from "@mui/material";
import pxToRem from "src/utils/functions/pxToRem";
import globalSettings from "src/constants/globalSettings";

export const Container = styled(Box)`
    display: flex;
    flex-flow: column;
    width: 100%;
    button {
        align-self: flex-start;
        margin-top: 0.8vw;
    }
    @media (max-width: 768px) {
        width: 100%;
        button {
            align-self: initial;
            margin-top: 24px;
        }
    }
`

export const Form = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: "'firstname lastname' 'email email' 'phone phone' 'company role' 'message message'",
  margin: '1.5vw 0',
  gap: '1vw',

  '& > div:nth-of-type(1)': {
    gridArea: 'firstname',
  },

  '& > div:nth-of-type(2)': {
    gridArea: 'lastname',
  },

  '& > div:nth-of-type(3)': {
    gridArea: 'email',
  },

  '& > div:nth-of-type(4)': {
    gridArea: 'phone',
  },

  '& > div:nth-of-type(5)': {
    gridArea: 'company',
  },

  '& > div:nth-of-type(6)': {
    gridArea: 'role',
  },

  '& > div:nth-of-type(7)': {
    gridArea: 'message',
  },

  [theme.breakpoints.down('sm')]: {
    gap: '1.25rem',
    display: 'flex',
    flexDirection: 'column'
  },
  [theme.breakpoints.down('md')]: {
    gap: '1.25rem',
  },
}));


export const Heading = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(40),
    lineHeight: '120%',
    color: globalSettings.Colors.secondary,
    '& p': {
      display: 'inline',
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(32),
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));

  export const ThankYouTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(40),
    fontWeight: 700,
    fontFamily: 'Barlow',
    textAlign: 'center',
    lineHeight: '120%',
    color: globalSettings.Colors.secondary,
    '& p': {
      display: 'inline',
      margin: 0,
      padding: 0,
    },
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(36),
      width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));

  export const ThankYouContent = styled(Typography)(({ theme }) => ({
    lineHeight: pxToRem(28),
    fontSize: pxToRem(18),
    fontWeight: 600,
    fontFamily: 'Barlow',
    opacity: 0.7,
    textAlign: 'center',
    width: '75%',
    color: globalSettings.Colors.secondary,
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      width: '100%',
      fontWeight: 400,
      fontFamily: 'Public Sans',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));

  export const MissingText = styled(Typography)(({ theme }) => ({
    lineHeight: pxToRem(22),
    fontSize: pxToRem(16),
    fontWeight: 600,
    fontFamily: 'Barlow',
    opacity: 0.7,
    color: globalSettings.Colors.red,
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      width: '100%',
      fontWeight: 400,
      fontFamily: 'Public Sans',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));

  export const ThankYouContainer = styled(Box)(({ theme }) => ({
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: pxToRem(32),
    [theme.breakpoints.down('md')]: {
     
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));

  export const ChipHeading = styled(Typography)(({ theme }) => ({
    fontFamily: 'Public Sans',
    fontWeight: 700,
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#EEE7DC',
    marginBottom: '16px',
    fontSize: pxToRem(12),
    [theme.breakpoints.down('md')]: {
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '100%',
    },
  }));