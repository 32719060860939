import { Box, styled } from "@mui/material";

interface ContainerProps{
    background_image:string
  }
  const {
    REACT_APP_CMS_HOST,
} = process.env as { [key: string]: string };
  export const Container = styled(Box)<ContainerProps>(({ theme,background_image }) => ({
    backgroundImage: `url(https://${REACT_APP_CMS_HOST}${background_image})`,
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat', 
    height: '100%',
    backgroundPosition: 'center',
    maxHeight: '700px'
  
  }));
